import React, { Component } from "react";
import Reveal from "react-reveal/Reveal/";
import Form from "./Form";
// import Wiz from "./Wiz";
import StudentRegistration from "./test";
import "./Wizard.css";

class Contact extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <div>
        <section className="contact-area" id="contact">
          <div className="container">
            <div className="row row-reverse">
              <div className="col-md-12">
                <Reveal effect="fadeInRight" duration={800}>
                  <div className="input_form">
                    <h4>Student Registration</h4>
                    <div className="wizard-test">
                      <StudentRegistration />
                    </div>
                    <div id="success">Your message succesfully sent!</div>
                    <div id="error">
                      Opps! There is something wrong. Please try again
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>
        <section></section>
        {/* <Footer jhonData={jhonData}/> */}
      </div>
    );
  }
}
export default Contact;
