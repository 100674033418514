import React, { Component } from "react";
import aboutus from "../image/about/aboutus.png";
import g10 from "../image/about/g10.png";
import g2 from "../image/about/g2.png";
import g from "../image/about/g.png";

import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class MissionVision extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Row className="class">
            <Col>About us</Col>
          </Row>
          <Row>
            <Col className="about">
              <img className="aboutus" src={aboutus}></img>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col className="content">
              <p>
                <strong className="strong"> WayYourChild</strong> provides one
                stop cloud software solution for school administration. We
                provide flexible,
                <strong className="strong"> SCHOL-specific</strong>
                software that is designed around the needs of our school staffs,
                parents & administrations. Our customers’ unique school ERP
                system, processes and operational requirements is built on the
                cloud AWS, with a deep understanding and refined solutions with
                enhanced version by considering all ground work realities and
                operational problems in School administration.
              </p>
              <p>
                Creativity is in our DNA. Our game-changing innovations are
                redefining the possibilities of digital experiences in School
                Administration. We connect content and data and introduce new
                technologies that democratize creativity, shape the next
                generation of storytelling and inspire entirely new categories
                of educations systems and processes.
              </p>
              <p>
                We know that parents are mostly worried when their kids are in
                school. WayYourChild provides you one stops solutions for your
                kids schooling. Whether it is about your kids Attendance,
                Homework’s, Fees, Exams, tracking the school bus etc. So stop
                worrying WayYourChild is here to help you at the ease of your
                fingertips.
              </p>
              <p>
                We’re always looking around the corner to develop new
                technologies and bring innovation to our clients, year after
                year.
              </p>
            </Col>
          </Row>
          <Card className="mission">
            <CardBody>
              <Row>
                <Col>
                  <img className="size"  src={g10}></img>
                </Col>
                <Col>
                  <h5> Our Mission </h5>
                  <p>
                    Our Mission are always to be persistent innovation with
                    qualitative services in what we do. Our first and utmost
                    mission is to provide optimal solutions with quality and
                    services at reasonable prices. For us customer satisfaction
                    is given top place. We are very friendly in our dealings to
                    the customers and it helps us retain existing clients and
                    expand customer circle. We always try to improve the quality
                    of our products by exploring innovative ideas. WayYourChild
                    exists to help school/education institutes to fulfill their
                    missions. Our specialty lies in helping School
                    Administration to use our product effectively, in a way that
                    supports their overall goals and their priorities.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br />
          <br />
          <Card className="mission">
            <CardBody>
              <Row>
                <Col>
                  <h5> Our Vision </h5>
                  <p>
                    In the era of fast-paced technology changes, our vision is
                    continuously delivering the best technology driven software
                    solution in School Administration. Currently our software
                    solution has been built upon latest Technology in REACT on
                    AWS cloud with secured data and with utmost integrity. Our
                    vision is to provide a great satisfaction and amazing
                    digital experiences in education system to each one of us
                    who are connected with us either school’s personnel, parents
                    or students. WayYourChild provides cutting edge digital
                    software solutions at fingertips via Mobile APP to all our
                    users in education institutes ERP system establishments to
                    act productively and efficiently with our new, smart and
                    technology driven school e-solutions.
                  </p>
                </Col>
                <Col className="g">
                  <img className="size"  src={g}></img>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br />
          <br />
          <Card className="mission">
            <CardBody>
              <Row>
                <Col>
                  <img className="size"  src={g2}></img>
                </Col>
                <Col>
                  <h5> Our Values</h5>
                  <p>
                    Delivering our services with utmost accountability. We
                    believe to be sincere, trustworthy and reliable in every
                    work and services we provide. Communicating with respect
                    with our clients, promoting honesty in our business and
                    encouraging and adopting new cutting edge technologies with
                    innovation in our software solutions. We continue to deliver
                    a highly creative and cost-effective system so that an
                    environment of collaboration can be build up among our
                    clients. We are always striving to connect new ideas with
                    school challenges and ground realities. We trust in to be
                    inclusive, open and actively engaged with our clients,
                    partners and communities we serve. We are proud to stand
                    behind work quality of work we deliver.
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br />
          <br />
        </div>
      </React.Fragment>
    );
  }
}

export default MissionVision;
