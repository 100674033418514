const jhonData = {
    "name": "John Deo.",
    "name2": "Richard Tea.",
    "name3": "Alexa Johnson",
    "role": "Visual Designer & Front-End Developer,",
    "ptext": "Creative Designer & Developer located in London.",
    "socialLinks":[
        {
          "name": "Facebook",
          "url": "http://www.linkedin.com/",
          "className": "social_facebook"
        },
        {
          "name":"twitter",
          "url":"http://www.twitter.com/",
          "className":"social_twitter"
        },
        {
          "name":"googleplus",
          "url":"http://www.googleplus.com",
          "className":"social_googleplus"
        },
        {
            "name":"pinterest",
            "url":"http://www.pinterest.com",
            "className":"social_pinterest"
          }
      ],
      "aboutme": "About Me",
      "aboutdetails": "I am Jhone Deo Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever It has survived not only five centuries, but also the leap into electronic but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing",
      "service":[
          {
            "id" : 1,
            "iconName": "icon-desktop",
            "serviceTitle": "Enterprise Software Solutions",
            "sDetails" : " We provide all the software solutions based on organizational needs using the most recent technology based architecture.The systems typically designed to provide high levels of transaction performance and data security."
          },
          {
            "id" : 2,
            "iconName": "icon-pencil",
            "serviceTitle": "Web Designing & Development ",
            "sDetails" : "We provide all technology based solutions for Web designing based on the business needs and implementing a user interface that helps your potential clients to understand your business and easily reach out to you."
          },
          {
            "id" : 3,
            "iconName": "icon-phone",
            "serviceTitle": "Integrated Mobile Application",
            "sDetails" : "Providing solutions for developing iOS & Android based mobile applications with the latest known technologies, which connect and control all of business data flows from a single platform for maximized productivity and organizational efficiency."
          },
          {
            "id" : 4,
            "iconName": "icon-tools",
            "serviceTitle": "Customizations",
            "sDetails" : "We provide enterprise software solutions in a very modular manner and we provide the customization in the software solutions as per the business needs and audience. We consistently refine our ERP by adopting positive feedback from our clients. "
          },
          {
            "id" : 5,
            "iconName": "icon-gears",
            "serviceTitle": "Support",
            "sDetails" : "We actually work 24X7 to customize our services as per the need and also refine our ERP by adopting positive feedback. The whole team of WYC works with the spirit of customer satisfaction. We support you to make your administration better and efficient. "
          },
          {
            "id" : 6,
            "iconName": "icon-linegraph",
            "serviceTitle": "We’re experienced",
            "sDetails" : "We have a team of extraordinary talented software professionals who believe to provide the best solutions as per the business needs using all the cutting edge technologies. We believe software solutions which are robust and secured in all means."
          }
      ],
       
       /*---------------------------------------------------------------------------------------------------------------*/ 
       "aboutfeatures": "Features",
       "featuresdetails": "I am Jhone Deo Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever It has survived not only five centuries, but also the leap into electronic but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing",
       "features":[
           {
             "id" : 1,
            //  "iconName": "icon-desktop",
              "image":"/Image/features/12.png",
             "featuresTitle": "Fees Management ",
             "fDetails" : " Complete solution for fees management and integrated Mobile app feature for fees to get the fees details,support online through seamless PAYUBIZ payment gateway",
             "link": "#/feeManagement"
            },
            {
             "id" : 2,
            //  "iconName": "icon-pencil",
            "image":"/Image/features/2.png",
             "featuresTitle": "School ERP Management ",
             "fDetails" : " A complete cloud based Digital Teachers and Students ERP system including all others staff.  Excellent UI interface of Mobile APP access via individual mobile numbers logins.",
             "link":"#/school"
            },
           {
             "id" : 3,
              // "iconName": "icon-presentation",
             "featuresTitle": "SMS and Notifications",
             "image":"/Image/features/10.png",
             "fDetails" : "Integrated solution for SMS and APP notifications. We provide Individual Communication, Employees Communication and Notification support through APP as well.",
             "link": "#/notification"
            },
            {
              "id" : 4,
              // "iconName": "icon-gears",
              "image":"/Image/features/1.png",
              "featuresTitle": "Inventory Management",
              "fDetails" : "Amazing feature of WYC, in which it provides a platform to track all the school inventory,purchase orders and also maintain balance sheets of an individual school session wise ",
              "link": "#/inventory"
             },
           {
             "id" : 5,
            //  "iconName": "icon-linegraph",
            "image":"/Image/features/8.png",
             "featuresTitle": "Transport Management",
             "fDetails" : "Complete solution for transport management and integrated Mobile app solution for real time vehicle tracking & stoppages notifications along with transport details. ",
             "link": "#/transport"
            },
           {
             "id" : 6,
            //  "iconName": "icon-tools",
            "image":"/Image/features/5.png",
             "featuresTitle": "Promote Student",
             "fDetails" : "WYC provides a very unique and complete hassle free option to promote the student into next class. There is also an option to mark a student who has left the session in between.",
             "link": "#/promote"
            }
       ],
      /*---------------------------------------------------------------------------------------------------------------------*/
      "extrafeatures": "Extra",
      "extradetails": "I am Jhone Deo Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever It has survived not only five centuries, but also the leap into electronic but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing",
      "extra":[
          {
            "id" : 1,
            // "iconName": "icon-desktop",
            "image":"/Image/features/4.png",
            "extraTitle": "Class Management",
            "eDetails" : " A unified and integrated system for managing the school’s academic courses and classes. It includes class definitions, compulsory and optional subject’s allotments in academics",
            "link": "#/class"
           },
          
          {
            "id" : 2,
            // "iconName": "icon-presentation",
            "image":"/Image/features/6.png",
            "extraTitle": "Student ERP",
            "eDetails" : "A unified and integrated portal of students with all the related details like Profiles, Fees structure and details, transport details, subjects, timetable, academic performance etc.",
            "link": "#/student"
           },
           {
             "id" : 3,
            //  "iconName": "icon-gears",
            "image":"/Image/features/9.png",
             "extraTitle": "Exam Management",
             "eDetails" : "WYC provides a very systematic and very enhanced Exam management features. Exam Patterns for whole session can be defined and mapped at each class and section level.",
             "link": "#/exam"
            },
          {
            "id" : 4,
            // "iconName": "icon-linegraph",
            "image":"/Image/features/7.png",
            "extraTitle": "Teacher ERP",
            "eDetails" : "A unified and integrated portal of teacher with all the related. Teachers can access via Mobile APP for their daily academic activities like student attendance, homeworks, exam.",
            "link": "#/attendance" 
           },
          {
            "id" : 5,
            // "iconName": "icon-tools",
            "image":"/Image/features/3.png",
            "extraTitle": "Admission Management",
            "eDetails" : " Automation solutions for all type of academic entrance exams and a streamlined admission process system",
            "link": "#/admission"
           },
           {
            "id" : 6,
            // "iconName": "icon-pencil",
            "image":"/Image/features/11.png",
            "extraTitle": "Leave Management ",
            "eDetails" : " Complete Software solutions for leave management for students and teachers and a complete integrated solution in Mobile APP",
            "link":"#/leave"
           }
      ],



      /*---------------------------------------------------------------------------------------------------------------------*/
      "contact":[
        {
          "id": "1",
          "title": "Contact Info",
          menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Meerapur Basai Taktakpur, Ekta Nagar Colony Varanasi, Uttar Pradesh,   IN - 221003',
            },
            {
              id: 2,
              icon: 'icon_phone',
              text: 'Phone: +91-995-366-6077 , +91-829-944-5763',
            },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: wayyourchild@gmail.com',
            },
            // {
            //   id: 4,
            //   icon: 'icon_globe',
            //   text: 'Website: http://example.com',
            // }
          ]
        },
      ],
    contacttitle: 'Contact Form',
}

export default jhonData;

