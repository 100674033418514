import React, { Component } from "react";
import image from "../../image/allCont/all7.png";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Row className="class">
            <Col>SMS AND NOTIFICATIONS</Col>
          </Row>
          <Row>
            <Col className="image">
              <img  className="size" src={image}></img>
            </Col>
          </Row>
          <Row>
            <Col className="content">
              <p>
                Integrated solution for SMS and APP notifications. We provide
                Individual Communication, School Level Communication, Class
                level Communication, Employees Communication and Notification
                support through APP as well.
                <h5> The following Features are:</h5>
              </p>
            </Col>
          </Row>
          <Card className="box">
            <CardHeader>
              <h4>SMS integration:</h4>
              WYC is integrated with SMS service provider
              <strong className="strong"> Bhasha-SMS </strong>for any type of
              SMS communication. SMS allotment is being done at school level and
              then School can be able to consume as per total no. of SMS
              allotted.
            </CardHeader>

            <CardBody>
              <h5>EMAIL integration:</h5>
              WYC is integrated with EMAIL service provider
              <strong className="strong"> Bhasha</strong> for any type of email
              communication
              <br />
              <br />
              <h5>School Communication:</h5>
              SMS can be schedule as a school notice. WYC provides a framework
              where it can send SMS to all the employees of school and also to
              all the student at class level.
              <br />
              <br />
              <h5>Class Communication:</h5>
              SMS can be schedule as a class level communication. WYC provides a
              framework where it can send SMS to all the students of a class.
              <br />
              <br />
              <h5>Individual Communication:</h5>
              SMS can be sent at individual level as well. SMS can be send to
              teachers/students also.
              <br />
              <br />
              <h5>Transport Communication:</h5>
              SMS can be sent as a transport SMS communication. SMS can be sent
              route wise, stoppages wise, Bus wise or to an individual.
              <br />
              <br />
              <h4 className="strong">(Notifications Mechanism)</h4>
              <h5>Notification: </h5>
              WYC provides a mobile application where any individual can receive
              notification without any incur charges either
              employees/Teachers/Students/parents.
              <br />
              <br />
              <h5>School Communication:</h5>
              Notification can be schedule as a school notice. WYC provides a
              framework where it can send Notification to all the employees of
              school and also to all the student at class level.
              <br />
              <br />
              <h5>Class Communication:</h5>
              SMS can be schedule as a school notice. WYC provides a framework
              where it can send SMS to all the employees of school and also to
              all the student at class level.
              <br />
              <br />
              <h5>Individual Communication: </h5>
              Notification can be sent at individual level as well. Notification
              can be send to teachers/students also.
              <br />
              <br />
              <h5>Transport Communication: </h5>
              Notification can be sent as a transport Notification
              communication. Notification can be sent route wise, stoppages
              wise, Bus wise or to an individual.
              <br />
              <br />
              <h4 className="strong">(Home Work Management)</h4>
              <hr/>
              <h5>Homework Feature for Teachers: </h5>
              WYC provides a salient feature of homework. In this any teacher
              can assign home-works based on the class & subject for which one
              is assigned. Teacher can attach any type of attachments (jpg, pdf,
              xlxs, zip etc.) along with the homework.
              <br />
              <br />
              <h5>Homework for Students: </h5>
              Student/Parent will get instant notification for the homework
              provided by a teacher. One can see the homework section based on
              <strong className="strong">“DUE” </strong>homework &{" "}
              <strong className="strong">“TODAY’S” </strong>homework. One can
              also filter based on subject and also based on dates given.
              Attachment can be downloaded/view by student/parents.
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Notification;
