import React, { useEffect } from "react";
import image from "../../image/new imgaes/Group633.png";
import lock  from "../../image/new imgaes/locktemplate.png";
import { Card, CardHeader, CardBody, Row,Button ,Col} from "reactstrap";
import { useLocation } from 'react-router-dom';
import API from "../../API";

const PaymentFailed = () => {
    const location = useLocation();

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);
      const paymentDetails = {
        status: urlParams.get('status'),
        txnid: urlParams.get('txnid'),
        amount: urlParams.get('amount'),
        hash:urlParams.get('hash')
      }
      console.log("payment details--------",paymentDetails)
        try{
            API.verifyPayment(paymentDetails).then(resp=>resp.json()).then((jsonRes)=>{
                if(jsonRes.responseCode===200){
                    console.log("data output----",jsonRes)
                }
            })

            }
            catch (error){
                console.log(error)
            }
        
      },[])
        return (
            <React.Fragment>
                <Row className="template-row">

                    <Card className="template">
                        <CardBody className="template-body">
                        <Col className="template-cover">
                           <img className="template-image" src={image}></img>
                           </Col>
                            <h4>Payment Failed!</h4>
                            <h6 className="template-h6">Oops, that definitely should not happen. We're sorry, please try agin later.</h6>
                            <Button className="template-button">Try again</Button><br/>
                            <Button className="template-buttonTwo">Back to home</Button>
                            <p className="tempalte-p"><img className="lock-template" src={lock}></img>The payment is perfectly secure.</p>
                        </CardBody>
                    </Card>
                </Row>

            </React.Fragment>
        )

}
export default PaymentFailed;