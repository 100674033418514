import React, { Component } from "react";
import image from "../../image/new imgaes/Error.png";
import { Card, CardHeader, CardBody, Row,Button ,Col} from "reactstrap";


class PaymentCancel extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <React.Fragment>
                <Row className="template-row">

                    <Card className="template">
                        <CardBody className="template-body">
                        <Col className="template-cover">
                           <img className="template-image" src={image}></img>
                           </Col>
                            <h4 style={{color:"#185ba3", marginTop:"20px"}}>PAGE NOT FOUND!</h4>
                            <h6 className="template-h6">We're sorry, the page you have looked for does not exist in our database!.</h6>
                            <Button className="Success-buttonTwo">Go Back to home page</Button>
                        </CardBody>
                    </Card>
                </Row>

            </React.Fragment>
        )

    }
}
export default PaymentCancel;