import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

const SliderAddress = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div className="sliderNav">
      <Nav tabs className="sliderNav">
        <NavItem >
          <NavLink
            className={({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Head Office
          </NavLink>
        </NavItem>
        <NavItem  >
          <NavLink
          className="nav-address"
            className={({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Bhadohi Office
          </NavLink>
        </NavItem>
        <NavItem >
          <NavLink
            className={({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            Noida Office
          </NavLink>
        </NavItem>
        <NavItem >
          <NavLink
            className={({ active: activeTab === '4' })}
            onClick={() => { toggle('4'); }}
          >
            Delhi office
          </NavLink>
        </NavItem>
        <NavItem >
          <NavLink
            className={({ active: activeTab === '5' })}
            onClick={() => { toggle('5'); }}
          >
            Middle East
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="sliderPane">
        <TabPane tabId="1">
          <Row >
            <Col sm="12">
              <Card className="card-meerapur">
                <CardText>Meerapur Basai Taktakpur, Ekta Nagar Colony Varanasi, Uttar Pradesh,   IN - 221003<br />Phone: +91-995-366-6077 , +91-829-944-5763<br /> Email: wayyourchild@gmail.com.</CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row >
            <Col sm="12">
              <Card className="card-meerapur">
                <CardText>MIG-1/57, RAJPURA BIDA COLONY,PHASE-1 <br />AURAI ROAD BHADOHI ,U.P ,INDIA<br /> 221401</CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row >
            <Col sm="12">
              <Card className="card-meerapur">
                <CardText>C-1205,Strategic Royal Court Plot No. GH-4C Sector 16B,<br /> Greater Noida UP <br/> PIN-201308 .</CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row >
            <Col sm="12">
              <Card className="card-meerapur">
                <CardText>2600/5, 1st Floor	Beadon Pura	Karol bagh,<br/> Delhi	INDIA<br/> PIN-110005</CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row >
            <Col sm="12">
              <Card className="card-meerapur">
                <CardText>Hamadan Street,<br/> Abu Dhabhi<br/> UAE.</CardText>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default SliderAddress;


