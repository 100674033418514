import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"; // Import useHistory instead of useNavigate
import image from "../../image/new imgaes/g12.png";
import API from "../../API";
import { Card, Row, Col, Button } from "react-bootstrap";
import { decodeSearchString } from "../../config/RouterConfig";
import lock from "../../image/new imgaes/locktemplate.png";
import { CardBody } from "reactstrap";

export default function SchoolPayNow() {
  const history = useHistory(); // Use useHistory hook instead of useNavigate

  const [paymentData, setPaymentData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    txnid: "",
    productinfo: "",
    amount: "",
    email: "",
    hash: "",
  });

  useEffect(() => {
    const search = window.location.hash.split("?")[1];
    if (search) {
      const schoolId = decodeSearchString("schoolId", search);
      const admissionNumber = decodeSearchString("admissionNumber", search);
      const monthArrayString = decodeSearchString("monthArray", search);
      const monthArray = monthArrayString ? monthArrayString.split(",") : [];

      if (schoolId) {
        handleHash(schoolId, admissionNumber, monthArray);
      }
    }
  }, []);

  const handleHash = async (schoolId, admission, months) => {
    try {
      let body = {
        "schoolId": schoolId,
        "admissionNumber": admission,
        "monthArray": months,
      };
      const response = await API.getSchoolFeeInitiate(body);
      const jsonRes = await response.json();

      if (jsonRes.responseCode === 200 && jsonRes.result) {
        setPaymentData((prev) => ({
          ...prev,
          firstname: jsonRes.result.firstname || "",
          lastname: jsonRes.result.lastname || "",
          phone: jsonRes.result.phone || "",
          txnid: jsonRes.result.txnid || "",
          productinfo: jsonRes.result.productinfo || "",
          amount: jsonRes.result.amount || "",
          email: jsonRes.result.email || "",
          hash: jsonRes.result.hash || "",
          monthArray: jsonRes.result.monthArray,
          schoolid: jsonRes.result.schoolid,
          sectionid: jsonRes.result.sectionid?._id,
          classid: jsonRes.result.classid?._id,
          studentid: jsonRes.result.studentid,
          sessionid: jsonRes.result.sessionid,
        }));
        console.log(jsonRes);
      } else {
        console.error("Error:", jsonRes.errors);
      }
    } catch (error) {
      console.error("Error fetching hash:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting payment form with data:", paymentData);
    try {
      const response = await API.initiateSchoolFee(paymentData);
      const jsonRes = await response.json();
      console.log(jsonRes, "jsonRes-----");
      if (jsonRes.responseCode === 200 && jsonRes.result) {
        // Using history.push instead of navigate
        history.push("/pay", { state: { info: jsonRes.result } });

        // Or with query parameters
        history.push(
          `/pay?info=${encodeURIComponent(JSON.stringify(jsonRes.result))}`
        );
        console.log(jsonRes);
      } else {
        console.error("Error:", jsonRes.errors);
      }
    } catch (error) {
      console.error("Error fetching hash:", error);
      debugger;
    }
  };

  console.log("paymentData", paymentData);

  return (
    <React.Fragment>
      <Row className="template-row">
        <Card className="template">
          <CardBody className="template-body">
            <Col className="template-cover">
              <img
                className="template-image"
                src={image}
                alt="Payment Template"
              />
            </Col>
            <h4 style={{ color: "#185ba3" }}>Please Do Payment!</h4>
            <p className="tempalte-p">
              <img className="lock-template" src={lock} alt="Security Lock" />
              The payment is perfectly secure.
            </p>

            <form onSubmit={handleSubmit}>
              <input type="hidden" name="key" value={paymentData.key} />
              <input type="hidden" name="txnid" value={paymentData.txnid} />
              <input type="hidden" name="amount" value={paymentData.amount} />
              <input
                type="hidden"
                name="productinfo"
                value={paymentData.productinfo}
              />
              <input
                type="hidden"
                name="firstname"
                value={paymentData.firstname}
              />
              <input type="hidden" name="email" value={paymentData.email} />
              <input type="hidden" name="phone" value={paymentData.phone} />
              <input
                type="hidden"
                name="lastname"
                value={paymentData.lastname}
              />
              <input
                type="hidden"
                name="studentid"
                value={paymentData.studentid}
              />
              <input
                type="hidden"
                name="sessionid"
                value={paymentData.sessionid}
              />
              <input type="hidden" name="classid" value={paymentData.classid} />
              <input
                type="hidden"
                name="sectionid"
                value={paymentData.sectionid}
              />
              <input
                type="hidden"
                name="schoolid"
                value={paymentData.schoolid}
              />
              <input
                type="hidden"
                name="monthArray"
                value={paymentData.monthArray}
              />
              <input type="hidden" name="surl" value={paymentData.surl} />
              <input type="hidden" name="furl" value={paymentData.furl} />
              <input type="hidden" name="curl" value={paymentData.curl} />
              <input type="hidden" name="hash" value={paymentData.hash} />

              <input type="hidden" name="udf1" value="" />
              <input type="hidden" name="udf2" value="" />
              <input type="hidden" name="udf3" value="" />
              <input type="hidden" name="udf4" value="" />
              <input type="hidden" name="udf5" value="" />

              {paymentData.amount && (
                <div className="mb-3">
                  <p className="font-weight-bold">
                    Amount to Pay: ₹{paymentData.amount}
                  </p>
                </div>
              )}

              <Button
                type="submit"
                className="Success-buttonTwo"
                disabled={!paymentData.hash}
              >
                Pay Now
              </Button>
            </form>
          </CardBody>
        </Card>
      </Row>
    </React.Fragment>
  );
}