import React, { Component } from "react";
import Sectiontitle from "../Banner/Sectiontitle";
import { Row, Card, CardHeader, CardBody, Col, Button } from "reactstrap";

class Blog extends Component {
  render() {
    return (
      <div>
        {/* <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/> */}
        <section className="blog_area" id="pricing">
          <div className="container">
            <Sectiontitle
              Title="Pricing"
              TitleP="Choose from the given plans"
            />
            <Row>
              <Col className="col-lg-4">
                <Card className=" basic">
                  <CardHeader
                    className="basicHeader"
                    style={{ backgroundColor: "#3ACCE1",borderRadius: "20px 20px 0px 0px"  }}
                  >
                    <span>Basic</span>
                  </CardHeader>
                  <CardBody className="cardbody-all">
                     
                  <img className="basic-img" src={require("../../image/pricing/basic.png")}></img>


                    <span className="span-ruppes">
                      <h1>
                        <img
                        className="ruppes"
                          src={require("../../image/pricing/ruppes.png")}
                        ></img>
                        40{" "}
                      </h1>
                      <p>Per student/months</p>
                    </span>

                    <Button className="views" type="button">
                     <a href="#/school_registration" style={{color:"white"}}>Get Started</a> 
                    </Button>
                    <ul className="dash">
                      <li>Dash Board</li>
                      <li>School Management</li>
                      <li>Promote/Session & Automation</li>
                      <li>Teacher's Leave Management</li>
                      <li>Student Management </li>
                      <li>Event & Calendar</li>
                      <li>Certificate</li>
                      <li>All Report's Automation</li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>

              <Col className="col-lg-4">
                <Card className=" basic">
                  <CardHeader
                    className="basicHeader"
                    style={{ backgroundColor: "#F9A726",borderRadius: "20px 20px 0px 0px"  }}
                  >
                    Advanced
                  </CardHeader>
                  <CardBody className="cardbody-all">
                  <img className="basic-img" src={require("../../image/pricing/Advanced.png")}></img>

                    <span className="span-ruppes">
                      <h1>
                        <img
                        className="ruppes"
                          src={require("../../image/pricing/ruppes.png")}
                        ></img>
                        70{" "}
                      </h1>
                      <p>Per student/months</p>
                    </span>

                    <Button className="views" type="button">
                    <a href="#/school_registration" style={{color:"white"}}>Get Started</a> 
                    </Button>
                    <ul className="dash">
                      <li>Dash Board</li>
                      <li>School Management</li>
                      <li>Class Management</li>
                      <li>Promote/Session & Automation</li>
                      <li>Teacher Management</li>
                      <li>Teacher's Delegation </li>
                      <li>Teacher's Time Table</li>
                      <li>Teacher's Leave Management</li>
                      <li>Student Management</li>
                      <li>Student’s Attendance</li>
                      <li>Homework</li>
                      <li>Time Table(Student)</li>
                      <li>Communication</li>
                      <li>Event & Calendar</li>
                      <li>Student Diary</li>
                      <li>Fee Management</li>
                      <li>Gallery</li>
                      <li>Certificate</li>
                      <li>All Report's Automation</li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>

              <Col className="col-lg-4">
                <Card className=" basic">
                  <CardHeader
                    className="basicHeader"
                    style={{ backgroundColor: "#EE219A", color: "white",borderRadius: "20px 20px 0px 0px", }}
                  >
                    <span>Premium</span>
                  </CardHeader>
                  <CardBody className="cardbody-all">
                  <img className="basic-img" src={require("../../image/pricing/premium.png")}></img>
                    <span className="span-ruppes">
                      <h1>
                        <img
                        className="ruppes"
                          src={require("../../image/pricing/ruppes.png")}
                        ></img>
                        100{" "}
                      </h1>
                      <p>Per student/months</p>
                    </span>

                    <Button className="views" type="button">
                    <a href="#/school_registration"style={{color:"white"}}>Get Started</a> 
                    </Button>

                    <ul className="dash">
                      <li>Dash Board</li>
                      <li>Offline Mobile APP</li>
                      <li>User Management</li>
                      <li>Branch Management</li>
                      <li>School Management</li>
                      <li>Admission Management</li>
                      <li>Class Management</li>
                      <li>Promote/Session & Automation</li>
                      <li>Teacher Management</li>
                      <li>Teacher's Delegation </li>
                      <li>Teacher Attendance</li>
                      <li>Teacher's Time Table</li>
                      <li>Teacher Leave Management</li>
                      <li>Student Management</li>
                      <li>Student’s Attendance</li>
                      <li>Student’s Leave Management</li>
                      <li>Homework</li>
                      <li>Time Table(Student)</li>
                      <li>Communication</li>
                      <li>Event & Calendar</li>
                      <li>Student Syllabus</li>
                      <li>Student Diary</li>
                      <li>Examination</li>
                      <li>Fee Management</li>
                      <li>Transport Management</li>
                      <li>Live Bus tracking</li>
                      <li>Bus Attendance</li>
                      <li>Income</li>
                      <li>Expenditure</li>
                      <li>Inventory</li>
                      <li>Gallery</li>
                      <li>Certificate</li>
                      <li>All Report's Automation</li>
                      <li>Biometric Integration</li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </div>
    );
  }
}
export default Blog;
