import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Row,
  CardBody,
  Label,
  Table,
  CardFooter,
} from "reactstrap";
import { decodeSearchString } from "../../config/RouterConfig";
import API from "../../API";
import Avatar from "../../assets/img/student.png";

const FeePay = () => {
  // Combined state object for better state management
  const printComponentRef = useRef(null);

  // States for printing
  const [printData, setPrintData] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [state, setState] = useState({
    monthsArray: [],
    isVisible: false,
    data: [],
    carryForwardFeeOpacity: null,
    paidMonthsFee: [],
    unpaidMonths: [],
    TotalDataOfApi: null,
    Totalpayment: 0,
    showError: false,
    totalPaymenttoPayFinal: "",
    requiredData: [],
  });

  // Separate states for independent values
  const [finalResult, setFinalResult] = useState([]);
  const [admissionNumber, setAdmissionNumber] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [carryForwardTotalFees, setCarryForwardTotalFees] = useState("");
  const [studentInfo, setStudentInfo] = useState({});
  const [feeHeadArray, setFeeHeadArray] = useState([]);
  const [reciptData, setReciptData] = useState([]);
  const [paidtotalPayment, setPaidTotalPayment] = useState([]);

  useEffect(() => {
    const search = window.location.hash.split("?")[1];
    const regId = decodeSearchString("schoolId", search);
    setSchoolId(regId);
  }, []);

  useEffect(() => {
    handlePaymentList();
  }, [studentInfo?.student]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "admissionNumber") {
      setAdmissionNumber(value);
    }
  };

  const handleSeach = async () => {
    try {
      const body = {
        schoolId: schoolId,
        admissionNumber: admissionNumber,
      };
      const response = await API.studentInfo(body);
      const jsonRes = await response.json();
      if (jsonRes.responseCode === 200) {
        setStudentInfo(jsonRes.result);
        setCarryForwardTotalFees(jsonRes.result?.feeInfo?.calculateTotalFees);
        // Reset other states when new search is performed
        setState((prev) => ({
          ...prev,
          monthsArray: [],
          isVisible: false,
          data: [],
          TotalDataOfApi: null,
        }));
        setFinalResult([]);
        setFeeHeadArray([]);
      } else {
        setStudentInfo({});
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePaymentList = () => {
    let filter = {
      studentId: { $eq: studentInfo?.student?._id },
      status: { $eq: "Accept" },
      // "type": { "$eq": "Normal" }
    };
    try {
      API.getPaymentList(JSON.stringify(filter))
        .then((res) => res.json())
        .then((jsonRes) => {
          if (jsonRes.responseCode === 200) {
            let reciptData = jsonRes.result.recentPayment;

            let paidtotalPayment =
              reciptData &&
              reciptData.reduce((acc, curr) => {
                return acc + Number(curr.txnInfo?.txnAmount || 0);
              }, 0);

            setPaidTotalPayment(paidtotalPayment);
            setReciptData(reciptData);
          } else {
            setReciptData([]);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotalFees = (data) => {
    const feeHeadMap = new Map();

    if (data && Array.isArray(data)) {
      data.forEach((item) => {
        const paidStatus = item.paymentStatus;
        const feeHeadName = item?.feeHead;

        if (Array.isArray(feeHeadName)) {
          feeHeadName.forEach((feeData) => {
            const names = feeData?.feeHeadId?.name;
            const amount = feeData.amount;

            if (state.monthsArray.length > 1) {
              if (paidStatus !== "PARTIAL" && amount !== 0) {
                if (feeHeadMap.has(names)) {
                  feeHeadMap.get(names).totalAmount += amount;
                  feeHeadMap.get(names).count += 1;
                } else {
                  feeHeadMap.set(names, {
                    names,
                    amount,
                    totalAmount: amount,
                    count: 1,
                  });
                }
              }
            } else if (amount !== 0) {
              if (feeHeadMap.has(names)) {
                feeHeadMap.get(names).totalAmount += amount;
                feeHeadMap.get(names).count += 1;
              } else {
                feeHeadMap.set(names, {
                  names,
                  amount,
                  totalAmount: amount,
                  count: 1,
                });
              }
            }
          });
        }
      });
    }

    return Array.from(feeHeadMap.entries()).map(([feeHeadName, data]) => ({
      feeHeadName,
      totalAmount: data.totalAmount,
      monthCount: data.count,
      amountPerMonth: data.amount,
    }));
  };

  const handleTable = async (e, item, index) => {
    const record = studentInfo?.feeInfo?.feeStruct;
    if (!record) return;

    // Calculate selected months and payment status
    const selectedMonths = [];
    const paidMonths = [];

    for (let i = 0; i <= index; i++) {
      if (
        record[i]?.paymentStatus === "UNPAID" ||
        record[i]?.paymentStatus === "PARTIAL"
      ) {
        selectedMonths.push(record[i]?.month);
      }
      if (record[i]?.paymentStatus === "PAID") {
        paidMonths.push(record[i]?.month);
      }
    }

    // Add the current month if it's paid
    if (record[index]?.paymentStatus === "PAID") {
      selectedMonths.push(record[index].month);
    }

    // Calculate total payable amount
    const totalPayableAmount = record.reduce(
      (total, item) => total + (item.payableAmount || 0),
      0
    );

    // Filter results based on payment status
    const filteredResult = record.filter(
      (o1) =>
        selectedMonths.includes(o1.month) &&
        ["UNPAID", "PARTIAL", "PAID"].includes(o1.paymentStatus)
    );

    // Update states
    setFinalResult(filteredResult);
    setState((prev) => ({
      ...prev,
      data: selectedMonths,
      monthsArray: filteredResult.map((item) => item.month),
      totalPayableAmount,
      carryForwardFeeOpacity: item,
      isVisible: true,
    }));

    // Prepare API request
    const body = {
      classId: studentInfo?.student?.classId?._id,
      sectionId: studentInfo?.student?.sectionId?._id,
      studentId: studentInfo?.student?._id,
      monthArray: selectedMonths,
    };

    try {
      const response = await API.getCalculateFeeInfo(body);
      const jsonRes = await response.json();

      if (jsonRes.success) {
        const TotalDataOfApi = jsonRes.data;
        const paidMonthsFee =
          TotalDataOfApi?.feeStructure?.filter(
            (x) => x.paymentStatus === "PAID"
          ) || [];
        const unpaidMonths =
          TotalDataOfApi?.feeStructure?.filter((x) =>
            ["UNPAID", "PARTIAL", "PAID"].includes(x.paymentStatus)
          ) || [];

        const newFeeHeadArray = calculateTotalFees(unpaidMonths);
        setFeeHeadArray(newFeeHeadArray);

        setState((prev) => ({
          ...prev,
          paidMonthsFee,
          unpaidMonths,
          TotalDataOfApi,
          Totalpayment: TotalDataOfApi?.totalFee || 0,
          showError: false,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          TotalDataOfApi: null,
        }));
      }
    } catch (error) {
      console.error("Error calculating fee info:", error);
    }
  };

  const handlePrint = (receiptData) => {
    setPrintData({
      studentInfo: receiptData,
      schoolData: receiptData?.schoolId,
    });
    setIsPrinting(true);
  };

  const onAfterPrint = () => {
    setIsPrinting(false);
    setPrintData(null);
  };

  const handlePay = () => {
    const url = `#/schoolpayNow?schoolId=${schoolId}&admissionNumber=${
      studentInfo?.student.admissionNumber
    }&monthArray=${encodeURIComponent(state?.monthsArray.join(","))}`;

    window.location.href = url;
  };

  const { TotalDataOfApi, monthsArray, showError, unpaidMonths } = state;

  return (
    <>
      <div className="">
        <div className="row">
          <Col lg="3">
            <FormGroup>
              <label
                className="student-lebel input_field_search"
                style={{ color: "black" }}
              >
                Admission Number{" "}
              </label>
              {""}
              <span className="valid">*</span>
              <Input
                type="text"
                id="admissionNumber"
                name="admissionNumber"
                onChange={handleChange}
                placeholder="Admission Number"
                className="input_field_search"
                required
              />
            </FormGroup>
          </Col>
        </div>
        <div className="btn-component mt-2">
          <Button
            className="btnn"
            type="submit"
            color="success"
            onClick={handleSeach}
          >
            Search
          </Button>
        </div>
        {/* <Card> */}
        {studentInfo?.feeInfo?.feeStruct?.length === 0 &&
        studentInfo?.feeInfo?.carryForwardTotal === 0 ? (
          <Row className="mt-3">
            <Col>
              <Alert color="warning" style={{ width: "25rem" }}>
                {"Data is Not Avaiable !"}
              </Alert>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {/* <CardBody style={{ backgroundColor: "white" }}> */}
        <Row className="mt-3" style={{ border: "none" }}>
          {studentInfo?.feeInfo?.feeStruct
            ? studentInfo?.feeInfo?.feeStruct?.map((item, index) => (
                <Card
                  key={index}
                  className={
                    item?.paymentStatus === "UNPAID"
                      ? "card_new_For_feeReport"
                      : item?.paymentStatus === "PAID"
                      ? "card_new_For_feeReport"
                      : item?.paymentStatus === "PARTIAL"
                      ? "card_new_For_feeReport"
                      : "card_new_For_feeReport"
                  }
                  style={{
                    opacity: state?.monthsArray?.includes(item?.month)
                      ? "0.5"
                      : "1",
                    backgroundColor:
                      item?.paymentStatus === "UNPAID"
                        ? "#bf0000 "
                        : item?.paymentStatus === "PAID"
                        ? "#0a5d00"
                        : item?.paymentStatus === "PARTIAL"
                        ? "#dab600"
                        : "",
                    textAlign: "center",
                    color: "white",
                    marginBottom: "1rem",
                    marginRight: "1rem",
                    paddding: "1rem",
                    flex: "1",
                    border: "none",
                    maxWidth: "calc(20% - 20px)", // Adjust the percentage and spacing as needed
                    display: "grid",
                    position: "relative", // To position the tooltip correctly
                  }}
                  onClick={(e) => handleTable(e, item, index)}
                  disabled={
                    item?.paymentStatus === "UNPAID"
                      ? false
                      : item?.paymentStatus === "PAID"
                      ? true
                      : true
                  }
                >
                  <div style={{ position: "relative" }}>
                    <p
                      className="months_Name_Report"
                      style={{
                        marginTop: "10px",
                        fontSize: "1rem", // Adjust the font size as needed
                      }}
                    >
                      {item?.month}
                      {/* <TooltipForPayment
                        data={item}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      /> */}
                    </p>
                    <hr
                      style={{
                        border: "1px dashed #FFFFFF",
                        opacity: "1",
                        marginTop: "-10px",
                      }}
                    />
                    <p
                      className="payable_heading_feeReport"
                      style={{ marginTop: "-10px" }}
                    >
                      Payable Fee
                    </p>
                    <p
                      className="amount_fee_feeReport"
                      style={{ marginTop: "-10px" }}
                    >
                      {item?.payableAmount}
                    </p>
                    <hr
                      style={{
                        border: "1px dashed #FFFFFF",
                        opacity: "1",
                        marginTop: "-10px",
                      }}
                    />
                    <p
                      className="payable_heading_feeReport"
                      style={{ marginTop: "-10px" }}
                    >
                      {item?.paymentStatus === "PARTIAL"
                        ? "Due Amount"
                        : "Total Amount"}
                    </p>
                    <p
                      className="amount_fee_feeReport"
                      style={{ marginTop: "-10px" }}
                    >
                      {item?.paymentStatus === "PARTIAL"
                        ? item?.dueAmount
                        : item?.payableAmount}
                    </p>
                  </div>
                </Card>
              ))
            : null}
          {studentInfo?.feeInfo?.feeStruct?.length === 0 &&
            studentInfo?.feeInfo?.carryForwardTotalFee !== 0 && (
              <Card
                // className="card_new_For_feeReport"
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "white",
                  marginBottom: "1rem",
                  marginRight: "1rem",
                  flex: "1",
                  maxWidth: "calc(20% - 20px)", // Adjust the percentage and spacing as needed
                  display: "grid",
                  position: "relative",
                  opacity: state?.carryForwardFeeOpacity ? "0.5" : "1",
                  backgroundColor:
                    state?.carryForwardTotalFee === 0 ? "#0a5d00" : "#bf0000",
                }}
                onClick={(e) => handleTable(e, "carryForward")}
              >
                <div style={{ position: "relative" }}>
                  <p
                    className="months_Name_Report"
                    style={{ marginTop: "10px", fontSize: "1rem" }}
                  >
                    CarryForward
                    <span>
                      {/* <TooltipForPayment
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                        data="carryForwardTotal"
                      /> */}
                    </span>
                  </p>
                  <hr
                    style={{
                      border: "1px dashed #FFFFFF",
                      opacity: "1",
                      marginTop: "-10px",
                    }}
                  />
                  <p
                    className="payable_heading_feeReport"
                    style={{ marginTop: "-10px" }}
                  >
                    Payable Fee
                  </p>
                  <p
                    className="amount_fee_feeReport"
                    style={{ marginTop: "-10px" }}
                  >
                    {carryForwardTotalFees !== 0 ? carryForwardTotalFees : 0}
                  </p>
                  <hr
                    style={{
                      border: "1px dashed #FFFFFF",
                      opacity: "1",
                      marginTop: "-10px",
                    }}
                  />
                  <p
                    className="payable_heading_feeReport"
                    style={{ marginTop: "-10px" }}
                  >
                    Due Amount
                  </p>
                  <p
                    className="amount_fee_feeReport"
                    style={{ marginTop: "-10px" }}
                  >
                    {carryForwardTotalFees}
                  </p>
                </div>
              </Card>
            )}
        </Row>

        {state.isVisible && (
          <>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={1}>
                    <FormGroup>
                      <img
                        src={
                          studentInfo?.student?.avatar
                            ? studentInfo?.student?.avatar
                            : Avatar
                        }
                        alt=""
                        style={{ borderRadius: "50%" }}
                        height="100px"
                        width="100px"
                      />
                    </FormGroup>
                  </Col>
                  <Col>
                    <Col>
                      <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                        Admission No : {studentInfo?.student?.admissionNumber}
                      </Label>
                    </Col>
                    <Col>
                      <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                        Total Paid Amount :{" "}
                        {paidtotalPayment ? paidtotalPayment : ""}
                      </Label>
                    </Col>
                  </Col>
                  <Col>
                    <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                      Full Name : {studentInfo?.student?.fullName}
                    </Label>
                  </Col>
                  <Col>
                    <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                      Class :{" "}
                      {studentInfo?.student?.classId?.name +
                        "-" +
                        studentInfo?.student?.sectionId?.name}
                    </Label>
                  </Col>
                  <Col>
                    <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                      Father Name :{" "}
                      {studentInfo?.student?.parentInfo?.father.name}
                    </Label>
                  </Col>
                </Row>

                <hr />
                <Row className="mt-2">
                  <Col>
                    <span className="studentInfoTOOLSpATENT">
                      {" "}
                      Pay For :{" "}
                      {finalResult?.map((elm, elmIndex) => {
                        return elm.month + " , ";
                      })}
                    </span>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    {finalResult.length !== 0 && (
                      <Table responsive className="table_student_report">
                        <thead
                          style={{
                            backgroundColor: "#003b5e",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <tr>
                            <th style={{ fontSize: "15px" }}>Sr.no</th>
                            <th style={{ fontSize: "15px" }}>Fee Head</th>
                            <th style={{ fontSize: "15px" }}>Total Month</th>
                            <th style={{ fontSize: "15px" }}>Amount</th>
                            <th style={{ fontSize: "15px" }}>Final Amount</th>
                          </tr>
                        </thead>
                        <tbody
                          style={{ textAlign: "center", color: "#0a0a0a" }}
                        >
                          {feeHeadArray &&
                            feeHeadArray.map((item, key) => {
                              if (item.totalAmount !== 0) {
                                return (
                                  <tr key={key}>
                                    <th scope="row">{key + 1}</th>
                                    <td className="penality_appliad_items">
                                      {item.feeHeadName}
                                    </td>
                                    <td className="penality_appliad_items">
                                      {item.monthCount}
                                    </td>
                                    <td className="penality_appliad_items">
                                      {item.amountPerMonth}
                                    </td>
                                    <td className="penality_appliad_items">
                                      {item.totalAmount}
                                    </td>
                                  </tr>
                                );
                              } else {
                                return null; // Skip rendering if totalAmount is 0
                              }
                            })}
                        </tbody>
                      </Table>
                    )}
                    {finalResult.length === 0 && (
                      <Table responsive className="table_student_report">
                        <thead
                          style={{
                            backgroundColor: "#003b5e",
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          <tr>
                            <th style={{ fontSize: "15px" }}>Sr.no</th>
                            <th style={{ fontSize: "15px" }}>Fee Head</th>
                            <th style={{ fontSize: "15px" }}>Total Month</th>
                            <th style={{ fontSize: "15px" }}>Amount</th>
                            <th style={{ fontSize: "15px" }}>Final Amount</th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            textAlign: "center",
                            color: "#0a0a0a",
                          }}
                        >
                          <tr>
                            <th scope="row">1</th>
                            <td className="penality_appliad_items">
                              School Fee
                            </td>
                            <td className="penality_appliad_items">0</td>
                            <td className="penality_appliad_items">0</td>
                            <td className="penality_appliad_items">0</td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                    <hr />
                    <Row>
                      <Col>
                        <p
                          className="penality_appliad_and_items"
                          style={{ textAlign: "end", marginRight: "5rem" }}
                        >
                          {" "}
                          {TotalDataOfApi?.previousDue !== 0 &&
                            (monthsArray.length === 1 ? (
                              <>Due Amount : {TotalDataOfApi?.previousDue} </>
                            ) : (
                              <>
                                {" "}
                                Previous Due Amount :{" "}
                                {TotalDataOfApi?.previousDue}{" "}
                              </>
                            ))}
                        </p>
                      </Col>
                    </Row>
                    {TotalDataOfApi?.carryForward ? (
                      <Row>
                        <Col>
                          <span
                            className="penality_appliad_and_items"
                            style={{
                              backgroundColor: "#119311",
                              padding: "0.8rem",
                              color: "white",
                            }}
                          >
                            {" "}
                            carryForward Amount : {TotalDataOfApi?.carryForward}
                          </span>
                        </Col>
                      </Row>
                    ) : null}
                    <Row>
                      <Col>
                        <p
                          className="penality_appliad_and_items"
                          style={{ textAlign: "end", marginRight: "5rem" }}
                        >
                          {" "}
                          {unpaidMonths[0]?.paymentStatus === "PARTIAL" &&
                            monthsArray.length === 1 &&
                            "Paid Amount : " + unpaidMonths[0]?.paidAmount}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <p className="penality_appliad_and_items">
                          {" "}
                          {TotalDataOfApi?.admissionFee ? (
                            <>
                              Admission Amount : {TotalDataOfApi?.admissionFee}
                            </>
                          ) : null}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <p
                          className="penality_appliad_and_items"
                          style={{
                            fontSize: "1.2rem",
                            textAlign: "end",
                            marginRight: "5rem",
                          }}
                        >
                          {" "}
                          Total : {TotalDataOfApi?.midTotal}
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row
                      style={{
                        backgroundColor: "#cd0808",
                        color: "white",
                        pading: "1.1rem",
                      }}
                    >
                      <Col lg={3} md={3} sm={3}>
                        <p
                          className="penality_appliad_and_items"
                          style={{
                            textAlign: "start",
                            marginTop: "0.5rem",
                            color: "white",
                          }}
                        >
                          {" "}
                          Penality Applied:{TotalDataOfApi?.penalty}
                        </p>
                      </Col>
                      <Col lg={3} md={3} sm={3}>
                        <p
                          className="penality_appliad_and_items"
                          style={{
                            textAlign: "center",
                            marginTop: "0.5rem",
                            color: "white",
                          }}
                        >
                          {" "}
                          Discount Applied : {TotalDataOfApi?.discount}
                        </p>
                      </Col>
                      <Col lg={3} md={3} sm={3}>
                        <p
                          className="penality_appliad_and_items"
                          style={{
                            textAlign: "end",
                            marginTop: "0.5rem",
                            color: "white",
                          }}
                        >
                          {" "}
                          Late Fee Applied :{TotalDataOfApi?.lateFee}{" "}
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <p
                          className="penality_appliad_and_items"
                          style={{
                            fontSize: "1.2rem",
                            textAlign: "end",
                            marginRight: "5rem",
                          }}
                        >
                          {" "}
                          Total Payable Amount : ₹{TotalDataOfApi?.totalFee}
                        </p>
                      </Col>
                    </Row>
                    <hr />
                  </Col>
                
                </Row>
              </CardBody>
              <CardFooter>
                <Button type="submit" color="success" onClick={handlePay}>
                  Pay Fee
                </Button>
              </CardFooter>
            </Card>
          </>
        )}
      </div>
      {/* <>
        <div style={{ display: "none" }}>
          {isPrinting.length > 0 && printData && (
            <ViewDownloadList
              ref={printComponentRef}
              studentInfo={printData.studentInfo}
              schoolData={printData.schoolData}
            />
          )}
        </div>
      </> */}
    </>
  );
};

export default FeePay;
