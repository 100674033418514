import React, { Component } from "react";

import Footer from "./Footer";
import SliderAddress from "./SliderAddress";
import { Parallax } from 'react-parallax';
import NavbarTwo from "./Navbar/NavbarTwo";
import StudentRegistration from "./Student/Student";
import Form from "./Form";

class Contact extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <React.Fragment>
        {/* <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/> */}
        <section className="contact-area" id="contact">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="input_form">
                  <div className="contact_info">
                    <h4>For Technical and Sale queries</h4>
                    <h5>WhatsApp: +91-9453047100 </h5>
                    <h5>Tel: +91-8299445763 & +91-995366077</h5>
                    <h5> wayyourchild@gmail.com</h5>
                    <h5>ajaydubey2010@gmail.com</h5>
                    <h5>aloksinha.itbhu@gmail.com</h5>

                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input_form">
                  <div className="contact_info">
                    <h4>Contact us</h4>
                    <Form />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </section>
        <section>
          <div className="container">
            <Parallax strength={280} className="testimonial_Address">
              <SliderAddress />
            </Parallax >
          </div>
        </section>
      </React.Fragment>

    );
  }
}
export default Contact;
