import React, { Component } from "react";
import SectionTitleTwo from "../Banner/SectionTitleTwo";
import BlogTwoitems from "../Blog/BlogTwoitems";
import Reveal from "react-reveal/Reveal/";
// import NavbarTwo from '../Navbar/NavbarTwo';
class BlogTwo extends Component {
  render() {
    return (
      <div>
        {/* <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/> */}
        <section className="blog_area_two" id="vision">
          <div className="container">
            <SectionTitleTwo
              tCenter="text-center"
              stitle=""
              btitle="Our Mission,Vision & Value"
            />
            <Reveal effect="fadeInUp" duration={1000}>
              <div className="row">
                <BlogTwoitems
                  bTitle="Mission"
                  bDetails="Our Mission are always to be persistent innovation with qualitative services in what we do.
                   Our first and utmost mission is to provide optimal solutions with quality and services at reasonable prices. 
                   For us customer satisfaction is given top place."
                  image="mission.png"
                />
                <BlogTwoitems
                  bTitle="Vision"
                  bDetails="Our vision is delivering the best technology driven software solution in School Administration. 
                 
                   Our vision is to provide a great satisfaction and amazing digital experiences in education 
                   system to each one of us who are connected ."
                  image="vision.png"
                />
                 <BlogTwoitems
                  bTitle="Vision"
                  bDetails="Our vision is delivering the best technology driven software solution in School Administration. 
                   Our vision is to provide a great satisfaction and amazing digital experiences in education system to each one 
                   of us who are connected ."
                  image="Value.png"
                />
              </div>
            </Reveal>
          </div>
        </section>
      </div>
    );
  }
}
export default BlogTwo;
