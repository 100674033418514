import React, { Component } from 'react';
import Sticky from 'react-stickynode';
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import cool from "../../image/open22.png";

class NavbarTwo extends Component {
    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            if (document) {
                document.documentElement.scrollTop = 0;
                document.scrollingElement.scrollTop = 0;
            }
        });
    }

    componentWillUnmount() {
        if (this.unlisten && typeof this.unlisten === 'function') {
            this.unlisten();
        }
    }



    render() {
        var { mClass, mContainer, mainlogo, stickylogo } = this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <nav className={`navbar navbar-expand-lg navbar-light ${mClass}`}>
                    <div className={`container ${mContainer}`}>
                        <a className="navbar-brand logo_h" href="index.html">
                            <img src={require("../../image/" + mainlogo)} alt="" />
                            <img src={require("../../image/" + stickylogo)} alt="" />
                        </a>
                        <a href="./" className="btn get-btn get-btn-two d-lg-none d-md-block login">Login</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <div className="collapse navbar-collapse offset" id="navbarSupportedContent">
                            <ul className="nav navbar-nav m-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="/" spy={true} smooth={true} offset={0} duration={500}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="mission" spy={true} smooth={true} offset={-86} duration={500}>
                                        About Us
                                    </Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="products_services" spy={true} smooth={true} offset={-86} duration={500}>
                                        Services
                                    </Link>
                                </li> */}
                                <li className="nav-item">
                                    <div class="dropdown nav-link">
                                        <Link class="nav-link " type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Product
                                         </Link>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <Link class="dropdown-item" to="/featurestotal"><b>School ERP Modules</b></Link>
                                        </div>
                                    </div>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="pricing" spy={true} smooth={true} offset={-86} duration={500}>
                                        Price
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="school_registration" spy={true} smooth={true} offset={-86} duration={500}>
                                        School Demo
                                    </Link>
                                </li>
                                <li className=" nav-item">
                                    <Link className="nav-link nav-contactus" activeClass="active" to="contactus" spy={true} smooth={true} offset={-86} duration={500}>
                                        Contact Us
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link icon-panel" href="https://school.wayyourchild.in/" target="_blank"><img src={cool} className="icon-image" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </Sticky>
        );
    }
}

export default NavbarTwo;
