import React from "react";
import NavbarTwo from "./component/Navbar/NavbarTwo";
import FBanner from "./component/Banner/frelencerBanner";
import ServiceTwo from "./component/ServiceTwo";
import BlogTwo from "./component/Blog/BlogTwo";
import SkillTwo from "./component/Skill/SkillTwo";
import jhonData from "./component/jhonData";
import Testimonial from "./component/Testimonial/Testimonial";
import Clientslogo from "./component/Clientslogo";
import Features from "./component/Features";

export const Home = (props) => (
  <div className="body_wrapper">
    <FBanner jhonData={jhonData} />
    <ServiceTwo rowFlex="" />
    <SkillTwo />
    <Features jhonData={jhonData}/>
    <BlogTwo />
    <Testimonial />
    <Clientslogo />
  </div>
);
