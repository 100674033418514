import React from "react";

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
                name: "",
                subject: "",
                phone: "",
                email: "",

            },
        };
    }

    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let errors = this.state.errors;

        switch (name) {
            case "name":
                errors.name = value.length === 0 ? "Name is not empty" : "";
                break;
            case "subject":
                errors.subject = value.length < 5 ? "Subject must be 5 characters" : "";
                break;
            case "phone":
                errors.phone = value.length < 5 ? "phone is not empty" : "";
                break;
            case "email":
                errors.email = value.length < 5 ? "Subject is not empty" : "";
                let appos = value.indexOf("@");
                let dots = value.lastIndexOf(".");

                if (appos < 1 || dots - appos < 2) {
                    errors.email = "please enter valid email";
                }
                break;

            default:
                break;
        }
        this.setState({ errors, [name]: value });
    };

    render() {
        const { errors } = this.state;
        return (
            <form className="form_class">
                <div className="row">
                    <div className="col-lg-3">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="School Name"
                        />
                        <p>{errors.name}</p>
                    </div>
                    <div className="col-lg-3">
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="School Name"
                        />
                        <p>{errors.name}</p>
                    </div>
                    <div className="col-lg-3">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Email Id"
                        />
                        <p>{errors.email}</p>
                    </div>
                    <div className="col-lg-6">
                        <input
                            type="text"
                            id="subject"
                            name="subject"
                            className="form-control"
                            placeholder="Subject"
                        />
                        <p>{errors.subject}</p>
                    </div>
                    <div className="col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            placeholder="Mobile Number"
                        />
                        <p>{errors.phone}</p>
                    </div>
                    <div className="col-lg-6">
                        <input
                            type="number"
                            className="form-control"
                            id="schoolCount"
                            name="schoolCount"
                            placeholder="School Count"
                        />
                    </div>
                </div>
                <button type="submit" className="btn send_btn theme_btn">
                    Send Message
                </button>
            </form>
        );
    }
}

export default Form;
