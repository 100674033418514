import React, { Component } from "react";
import Sectiontitle from "../component/Banner/Sectiontitle";
import Fade from "react-reveal/Fade";
import { Button } from "reactstrap";

class FeaturesTotal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Isvisible: false,
    };
  }

  changeHandle = () => {
    this.setState({ Isvisible: true });
  };

  render() {
    let jhonData = this.props.jhonData;
    var { wClass } = this.props;
    return (
      <section className={`${wClass}`} id={"features"}>
        {/* <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/> */}
        
        <div className="Modules">
          <div className="container">
          <Sectiontitle Title="School ERP Modules" TitleP="" />
          <Fade bottom cascade duration={1000}>
            <div className={"row"}>
              {jhonData &&
                jhonData.features &&
                jhonData.features.map((item) => {
                  return (
                    <div className="col-lg-4 col-sm-6" key={item.id}>
                      <div
                        className="work_item wow fadeInUp"
                        data-wow-delay="0.1s"
                      >
                        <img src={item.image} className="img"></img>
                        <a href=".#">
                          {/* <i className={item.iconName}></i> */}
                          <h2 className="t_color">{item.featuresTitle}</h2>
                        </a>
                        <p>{item.fDetails}</p>
                        <a href={item.link} type="button" className="read">
                          <span> Read more &#8594;</span>
                        </a>
                      </div>
                    </div>
                  );
                })}

              <div className={"row"}>
                {jhonData &&
                  jhonData.extra &&
                  jhonData.extra.map((item) => {
                    return (
                      <div className="col-lg-4 col-sm-6" key={item.id}>
                        <div
                          className="work_item wow fadeInUp"
                          data-wow-delay="0.1s"
                        >
                          <img src={item.image} className="img"></img>
                          <a href="">
                            {/* <i className={item.iconName}></i> */}
                            <h2 className="t_color">{item.extraTitle}</h2>
                          </a>
                          <p>{item.eDetails}</p>
                          <a href={item.link} type="button" className="read">
                            <span>Read more &#8594;</span>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Fade>
        </div>
        </div>
      </section>
    );
  }
}

export default FeaturesTotal;
