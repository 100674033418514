import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import SectionTitleTwo from "../../component/Banner/SectionTitleTwo";
// import CounterItem from '../Skill/CounterItem';
import NavbarTwo from "../Navbar/NavbarTwo";
import Service from "../Service";
import jhonData from "../jhonData";
import Footer from "../Footer";
import TestimonialTwo from "../Testimonial/TestimonialTwo";
// import ScrollSpyPage from '../../component/smoothScroll.js'

class SkillTwo extends Component {
  componentDidMount() {
    function countup(el, target) {
      let data = { count: 0 };
      anime({
        targets: data,
        count: [0, target],
        duration: 2000,
        round: 1,
        delay: 200,
        easing: "easeOutCubic",
        update() {
          el.innerText = data.count.toLocaleString();
        },
      });
    }

    function makeCountup(el) {
      const text = el.textContent;
      const target = parseInt(text, 10);

      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            countup(el, target);
            io.unobserve(entry.target);
          }
        });
      });

      io.observe(el);
    }

    const els = document.querySelectorAll("[data-countup]");

    els.forEach(makeCountup);
  }
  render() {
    return (
      <div>
        {/* <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/>     */}
        <section className="skill_area_two bg_color" id="skill">
          <div className="container">
            <div className="row align-items-center">
              {/* <div className="col-lg-6">
                            <div className="skill_content_two">
                                <SectionTitleTwo stitle="" btitle="Products"/>
                                <div  id ="fea" className="row">
                                <span>School ERP | Cloud Solution | Automation</span>
                                <span>School ERP | Cloud Solution | Automation</span>                                      
                                </div>
                            </div>
                        </div> */}
              <div className="col-lg-6">
                {/* <img className="fact_img" src={require('../../image/mobile.svg')} alt=""/> */}
              </div>
            </div>
          </div>
        </section>
        <section>
          <Service wClass="work_area_two" jhonData={jhonData} />
        </section>
        <TestimonialTwo />
      </div>
    );
  }
}
export default SkillTwo;
