import React, { Component } from "react";
import SectionTitleTwo from "../component/Banner/SectionTitleTwo";
import Footer from "./Footer";
import jhonData from "./jhonData";
import NavbarTwo from "./Navbar/NavbarTwo";

class ServiceTwo extends Component {
  render() {
    let { rowFlex } = this.props;
    return (
      <div>
        {/* <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/> */}
        <div className="service_area_two bg_color" id="service">
          <div className="container">
            <div className={`row align-items-center ${rowFlex}`}>
              <div className="col-lg-7">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="home"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                  >
                    <div className="service_img">
                      <img src={require("../image/about/aboutus.png")} alt="" />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="service_img">
                      <img src={require("../image/service.svg")} alt="" />
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="contact"
                    role="tabpanel"
                    aria-labelledby="contact-tab"
                  >
                    <div className="service_img">
                      <img src={require("../image/service.svg")} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="service_content">
                  <SectionTitleTwo stitle="" btitle="About Us" />
                  <p>
                    WayYourChild provides one stop cloud software solution for
                    school administration. Creativity is in our DNA. Our game
                    changing innovations are redefining the possibilities of
                    digital experiences in School Adminstration. We connect
                    content and data and introduce new technologies that
                    democratize creativity, shape the next generationof
                    storytelling and inspire entirely new categories of
                    education systems and processes.
                    <a href="#/mission"  type="button" className="read">
                      <span>Read more &#8594;</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer jhonData={jhonData}/> */}
      </div>
    );
  }
}

export default ServiceTwo;
