import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { decodeSearchString } from "../../config/RouterConfig";
import API from "../../API";
import Avatar from "../../assets/img/student.png";

const TransportPay = () => {
  // Basic states
  const [schoolId, setSchoolId] = useState("");
  const [admissionNumber, setAdmissionNumber] = useState("");
  const [studentInfo, setStudentInfo] = useState(null);
  const [receiptData, setReceiptData] = useState([]);
  const [paidTotalPayment, setPaidTotalPayment] = useState(0);

  // Transport specific states
  const [monthsName, setMonthsName] = useState([]);
  const [transportMonthsData, setTransportMonthsData] = useState([]);
  const [carryForward, setCarryForward] = useState(0);
  const [enabledRows, setEnabledRows] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [transportCalculatedFee, setTransportCalculatedFee] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  // Payment calculation states
  const [unpaidMonths, setUnpaidMonths] = useState([]);
  const [paidMonths, setPaidMonths] = useState([]);
  const [payment, setPayment] = useState(0);
  const [finalPayment, setFinalPayment] = useState(0);
  const [midTotal, setMidTotal] = useState(0);

  useEffect(() => {
    const search = window.location.hash.split("?")[1];
    const regId = decodeSearchString("schoolId", search);
    setSchoolId(regId);
  }, []);

  useEffect(() => {
    if (studentInfo?.student?._id) {
      handlePaymentList();
    }
  }, [studentInfo?.student?._id]);

  const monthsBetweenTwoDates = (sessionData) => {
    const { startDate, endDate } = sessionData;
    const namedMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const stringToDate = (dateString) => {
      const [year, month, day] = dateString
        .split("-")
        .map((num) => parseInt(num, 10));
      const date = new Date(year, month - 1, day);
      date.setHours(2);
      return date;
    };

    const getMonthsBetween = (start, end) => {
      const months = [];
      const startDate = stringToDate(start);
      const endDate = stringToDate(end);

      while (startDate <= endDate) {
        months.push(namedMonths[startDate.getMonth()]);
        startDate.setMonth(startDate.getMonth() + 1);
      }

      return months;
    };

    const months = getMonthsBetween(startDate, endDate);
    setMonthsName(months);
    return months;
  };

  const handleSearch = async () => {
    try {
      const response = await API.getTranportStudentDetails({
        schoolId,
        admissionNumber,
      });
      const jsonRes = await response.json();

      if (jsonRes.responseCode === 200) {
        const months = monthsBetweenTwoDates(jsonRes?.result?.sessionId);
        const rowData = jsonRes.result.feeInfo?.feeStructure;

        let transportData = [];
        if (rowData?.length > 0) {
          transportData = months
            .map((month) => rowData.find((x) => x.month === month))
            .filter((x) => x !== undefined);
        }
        const enabled = transportData.filter(
          (item) => item.enableStatus === true
        );
        setEnabledRows(enabled);
        setTransportMonthsData(enabled);
        setCarryForward(jsonRes?.result?.feeInfo?.carryForwardTotal);
        setStudentInfo(jsonRes?.result);
        setIsVisible(false);
        setSelectedMonths([]);
      } else {
        setStudentInfo(null);
      }
    } catch (error) {
      console.error("Error fetching student details:", error);
    }
  };

  const handleTransportTable = async (e, item, index) => {
    if (!studentInfo?.student) return;

    const enabled = transportMonthsData.filter(
      (item) => item.enableStatus === true
    );
    let selectedData = [];
    let paidMonthsList = [];

    // Calculate selected months and paid months
    for (let i = 0; i <= index; i++) {
      if (
        enabled[i].paymentStatus === "UNPAID" ||
        enabled[i].paymentStatus === "PARTIAL"
      ) {
        selectedData.push(enabled[i].month);
      }
      if (enabled[i].paymentStatus === "PAID") {
        paidMonthsList.push(enabled[i].month);
      }
    }

    setSelectedMonths(selectedData);
    setPaidMonths(paidMonthsList);

    try {
      const response = await API.postCalculateTransportInfo({
        classId: studentInfo.student.classId?._id,
        monthArray: selectedData,
        sectionId: studentInfo.student.sectionId?._id,
        studentId: studentInfo.student._id,
      });

      const jsonRes = await response.json();

      if (jsonRes.success) {
        const calculatedFee = jsonRes.data;
        const unpaidMonthsList = calculatedFee.feeStrucutureInfo.filter(
          (x) => x.paymentStatus === "UNPAID" || x.paymentStatus === "PARTIAL"
        );

        let totalMid = unpaidMonthsList.reduce(
          (acc, curr) => acc + curr.dueAmount,
          0
        );
        totalMid += calculatedFee.carryForwardTotal;

        let count = 0;
        let finalPaymentAmount = 0;
        let paymentAmount = 0;

        unpaidMonthsList.forEach((item) => {
          if (selectedData.length > 1) {
            if (item.paymentStatus === "PARTIAL") {
              count = 0;
              finalPaymentAmount = 0;
            } else {
              count++;
              finalPaymentAmount += item.paymentAmount;
              paymentAmount = item.paymentAmount;
            }
          } else {
            count++;
            finalPaymentAmount += item.paymentAmount;
            paymentAmount = item.paymentAmount;
          }
        });

        setTransportCalculatedFee(calculatedFee);
        setMidTotal(totalMid);
        setFinalPayment(finalPaymentAmount);
        setPayment(paymentAmount);
        setUnpaidMonths(unpaidMonthsList);
        setIsVisible(true);
      }
    } catch (error) {
      console.error("Error calculating transport info:", error);
    }
  };

  const handlePaymentList = async () => {
    try {
      const filter = {
        studentId: { $eq: studentInfo?.student?._id },
      };

      const response = await API.getTransportPaymentList(
        JSON.stringify(filter)
      );
      const jsonRes = await response.json();

      if (jsonRes.success) {
        const receipts = jsonRes.data.recentPayment;
        console.log(receipts);
        const totalPaid = receipts.reduce(
          (acc, curr) => acc + Number(curr.txnInfo?.txnAmount || 0),
          0
        );

        setPaidTotalPayment(totalPaid);
        setReceiptData(receipts);
      } else {
        setReceiptData([]);
      }
    } catch (error) {
      console.error("Error fetching payment list:", error);
    }
  };

  const handlePay = () => {
    const url = `#/schoolpayNow?schoolId=${schoolId}&admissionNumber=${
      studentInfo?.student.admissionNumber
    }&monthArray=${encodeURIComponent(selectedMonths.join(","))}`;
    window.location.href = url;
  };

  const renderPaymentCard = (item, index) => {
    const getStatusColor = (status) => {
      switch (status) {
        case "UNPAID":
          return "#bf0000";
        case "PAID":
          return "#0a5d00";
        case "PARTIAL":
          return "#dab600";
        default:
          return "";
      }
    };

    return (
      <Card
        key={index}
        className="card_new_For_feeReport"
        style={{
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          marginBottom: "1rem",
          marginRight: "1rem",
          flex: "1",
          maxWidth: "calc(20% - 20px)",
          display: "grid",
          position: "relative",
          opacity: selectedMonths.includes(item.month) ? "0.5" : "1",
          backgroundColor: getStatusColor(item.paymentStatus),
        }}
        onClick={(e) => handleTransportTable(e, item, index)}
      >
        <div style={{ position: "relative" }}>
          <p
            className="months_Name_Report"
            style={{ marginTop: "10px", fontSize: "1rem" }}
          >
            {item.month}
          </p>
          <hr
            style={{
              border: "1px dashed #FFFFFF",
              opacity: "1",
              marginTop: "-10px",
            }}
          />
          <p
            className="payable_heading_feeReport"
            style={{ marginTop: "-10px" }}
          >
            Payable Fee
          </p>
          <p className="amount_fee_feeReport" style={{ marginTop: "-10px" }}>
            {item.paymentAmount || 0}
          </p>
          <hr
            style={{
              border: "1px dashed #FFFFFF",
              opacity: "1",
              marginTop: "-10px",
            }}
          />
          <p
            className="payable_heading_feeReport"
            style={{ marginTop: "-10px" }}
          >
            {item.paymentStatus === "PARTIAL" ? "Due Amount" : "Total Amount"}
          </p>
          <p className="amount_fee_feeReport" style={{ marginTop: "-10px" }}>
            {item.paymentStatus === "PARTIAL"
              ? item.dueAmount
              : item.paymentAmount}
          </p>
        </div>
      </Card>
    );
  };

  const renderStudentInfo = () => {
    if (!isVisible) return null;

    return (
      <Card>
        <CardBody>
          <Row>
            <Col lg={1}>
              <FormGroup>
                <img
                  src={
                    studentInfo?.student?.avatar
                      ? studentInfo?.student?.avatar
                      : Avatar
                  }
                  alt=""
                  style={{ borderRadius: "50%" }}
                  height="100px"
                  width="100px"
                />
              </FormGroup>
            </Col>
            <Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT input_field_search">
                  Admission No : {studentInfo?.student?.admissionNumber}
                </Label>
              </Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                  Total Paid Amount : {paidTotalPayment}
                </Label>
              </Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                  Driver Name : {studentInfo?.allocatedStop?.driverId?.fullName}
                </Label>
              </Col>
            </Col>
            <Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                  Full Name : {studentInfo?.student?.fullName}
                </Label>
              </Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                  StopName : {studentInfo?.allocatedStop?.stopId?.name}
                </Label>
              </Col>
            </Col>
            <Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                  Class :{" "}
                  {studentInfo?.student?.classId?.name +
                    "-" +
                    studentInfo?.student?.sectionId?.name}
                </Label>
              </Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                  Route Name : {studentInfo?.allocatedStop?.routeId?.name}
                </Label>
              </Col>
            </Col>
            <Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                  Father Name : {studentInfo?.student?.parentInfo?.father.name}
                </Label>
              </Col>
              <Col>
                <Label className="label-style-text ml-lg-1 studentInfoTOOLSpATENT">
                  Bus No: {studentInfo?.allocatedStop?.vehicleId?.name}
                </Label>
              </Col>
            </Col>
          </Row>

          {renderPaymentDetails()}
        </CardBody>
      </Card>
    );
  };

  const renderPaymentDetails = () => {
    if (!transportCalculatedFee) return null;

    return (
      <>
        <hr />
        <Row className="mt-2">
          <Col>
            <span className="studentInfoTOOLSpATENT">
              Pay For: {unpaidMonths?.map((elm) => elm.month).join(", ")}
            </span>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Table responsive className="table_student_report">
              <thead
                style={{
                  backgroundColor: "#003b5e",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th>Sr.no</th>
                  <th>Fee Head</th>
                  <th>Total Month</th>
                  <th>Amount</th>
                  <th>Final Amount</th>
                </tr>
              </thead>
              <tbody style={{ textAlign: "center" }}>
                <tr>
                  <th scope="row">1</th>
                  <td>Transport Fee</td>
                  <td>{unpaidMonths?.length}</td>
                  <td>{payment}</td>
                  <td>{finalPayment}</td>
                </tr>
              </tbody>
            </Table>

            {transportCalculatedFee.carryForwardTotal > 0 && (
              <div className="mt-3 p-2 bg-success text-white">
                Carry Forward Amount: {transportCalculatedFee.carryForwardTotal}
              </div>
            )}

            <div className="text-end mt-3">
              <p>Total: {midTotal}</p>
              <div className="bg-danger text-white p-3 mt-3">
                <Row>
                  <Col>Penalty: {transportCalculatedFee.penaltyTotal}</Col>
                  <Col>Discount: {transportCalculatedFee.discountTotal}</Col>
                  <Col>Late Fee: {transportCalculatedFee.lateFee}</Col>
                </Row>
              </div>
              <p className="mt-3 h5">
                Total Payable Amount: ₹{transportCalculatedFee.feeTotal}
              </p>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const renderCarryForwardCard = () => {
    if (enabledRows.length > 0 || carryForward === 0) return null;

    return (
      <Card
        style={{
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          marginBottom: "1rem",
          marginRight: "1rem",
          flex: "1",
          maxWidth: "calc(20% - 20px)",
          display: "grid",
          position: "relative",
          backgroundColor: "#bf0000",
        }}
        onClick={(e) => handleTransportTable(e, "carryForward")}
      >
        <div style={{ position: "relative" }}>
          <p
            className="months_Name_Report"
            style={{ marginTop: "10px", fontSize: "1rem" }}
          >
            CarryForward
          </p>
          <hr
            style={{
              border: "1px dashed #FFFFFF",
              opacity: "1",
              marginTop: "-10px",
            }}
          />
          <p
            className="payable_heading_feeReport"
            style={{ marginTop: "-10px" }}
          >
            Payable Fee
          </p>
          <p className="amount_fee_feeReport" style={{ marginTop: "-10px" }}>
            {carryForward}
          </p>
          <hr
            style={{
              border: "1px dashed #FFFFFF",
              opacity: "1",
              marginTop: "-10px",
            }}
          />
          <p
            className="payable_heading_feeReport"
            style={{ marginTop: "-10px" }}
          >
            Due Amount
          </p>
          <p className="amount_fee_feeReport" style={{ marginTop: "-10px" }}>
            {carryForward}
          </p>
        </div>
      </Card>
    );
  };

  return (
    <div className="p-4">
      <Row>
        <Col lg={3}>
          <FormGroup>
            <Label
              className="student-label input_field_search"
              style={{ color: "black" }}
            >
              Admission Number <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              id="admissionNumber"
              name="admissionNumber"
              value={admissionNumber}
              onChange={(e) => setAdmissionNumber(e.target.value)}
              placeholder="Enter Admission Number"
              className="input_field_search"
              required
            />
          </FormGroup>
        </Col>
      </Row>

      <div className="mt-3">
        <Button
          color="success"
          onClick={handleSearch}
          disabled={!admissionNumber}
        >
          Search
        </Button>
      </div>

      {studentInfo &&
        studentInfo?.feeInfo?.feeStruct?.length === 0 &&
        studentInfo?.feeInfo?.carryForwardTotal === 0 && (
          <Alert color="warning" className="mt-3 w-25">
            Data is Not Available!
          </Alert>
        )}

      <Row className="mt-3 flex-wrap">
        {transportMonthsData.map((item, index) =>
          renderPaymentCard(item, index)
        )}
        {renderCarryForwardCard()}
      </Row>

      {renderStudentInfo()}

      {isVisible && unpaidMonths.length > 0 && (
        <div className="mt-3">
          <Button
            color="success"
            onClick={handlePay}
            className="d-flex align-items-center gap-2"
          >
            Pay Now
          </Button>
        </div>
      )}
    </div>
  );
};

export default TransportPay;
