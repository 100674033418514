import React, { Component } from "react";
import FormSchool from "./FormSchool";

class FormSchoolTwo extends Component {
    render() {
         return (
            <React.Fragment>
                {/* <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/> */}
                <section className="contact-area" id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="input_form">
                                    <div className="contact_info">
                                        <h4>School Demo/Registration</h4>
                                    <FormSchool/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}


            export default FormSchoolTwo;