import React, { Component } from "react";
import image from "../../image/allCont/all10.png";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class StudentManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Row className="class">
            <Col> FEES MANAGEMENT & ONLINE FEES PAYMENT</Col>
          </Row>
          <Row>
            <Col className="image">
              <img className="size" src={image}></img>
            </Col>
          </Row>
          <Row>
            <Col className="content">
              <p>
                Complete solution for fees management and integrated Mobile app
                feature for fees to get the fees details, due amount and support
                online payment of fees through seamless PAYUBIZ payment gateway
              </p>
            </Col>
          </Row>
          <Card className="box">
            <CardHeader>
              <h4>FEES MANAGEMENT </h4>
              WYC provides a complete cloud solution for fees setup, invoice
              generation, fees payment and reports for the internal school use.
              <h5> The following Features are:</h5>
            </CardHeader>

            <CardBody>
              <h5>Admission Fees:</h5>
              WYC system is fully compliant to provide a framework for admission
              fees for new student.
              <br />
              <br />
              <h5>Fees Structure:</h5>
              WYC provides a very flexible way to create any type of fees
              structure and fees payment schedule. It provides a framework where
              school can define his own fees payment schedule, fees heads and
              can define the fees structure to any class level. WYC provides to
              create fees heads for monthly, quarterly, once, yearly.
              <br />
              <br />
              <h5>Discount Feature:</h5>
              WYC provides a feature to include discount given at student level.
              Also provides a facility to impose any penalty anytime against
              student. WYC also generates a report for the all the students who
              has been subsidized.
              <br />
              <br />
              <h5>Fees Groups: </h5>
              WYC provides a salient feature to define fees structure as per the
              category of student based on EWS / CASTE (i.e. OBC, GENERAL). We
              can define different fees structure based on CASTE/EWS quota.
              <br />
              <br />
              <h5>Fees invoice:</h5>
              Complete automation of Fees invoice. System has integrated with
              fully automatic invoice generation mechanism based on predefined
              date. It generates and invoice against which fees should be paid
              by parents for their school.
              <br />
              <br />
              <h5>Fee Reports:</h5>
              WYC provides all kind of customized report for fees dues and
              collection and remaining. WYC can produce overdue fees, fully paid
              fees, fees remaining based on minimum amount. It can also generate
              report based on class and section basis and also on individual
              basis. WYC also provides to generate report of daily collection of
              fees and also pull up the report based on date range given. WYC
              provides fully customized reports. WYC also integrated with SMS
              integration to send reminder for overdue fees for any individual.
              <br />
              <br />
              <h5>Fees payments and collection:</h5>
              WYC provides a wide variety of fees collection and its
              segregation. Fees can be collected via cheque/cash/POS machine.
              WYC also provides a mobile app feature where any individual can
              see his invoice, due fees. Mobile is having Seamless Integration
              with PAYU-BIZ aggregator, where any individual can do fees payment
              with nominal service charges. Any parent/individual can do the
              fees online by just login to our website and can also do the fees
              payment at any point of time. Fees payment supported through
              Credit/Debit Card, Net Banking, UPI and other payment modes.
              <br />
              <br />
              <h5>Fees Reminder and collection:</h5>
              WYC also integrated with SMS integration to send reminder for
              overdue fees for any individual. The system can send messages to
              individual student at any point of student having Overdue fees.
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default StudentManagement;
