import React, { Component } from "react";
import image from "../../image/allCont/all6.png";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Row className="class">
            <Col> TEACHER ERP MANAGEMENT & BIOMETRIC INTEGRATION</Col>
          </Row>
          <Row>
            <Col className="image">
              <img className="size" src={image}></img>
            </Col>
          </Row>
          <Row>
            <Col className="content">
              <p>
                A unified and integrated portal of teacher with all the related.
                Teachers can access via Mobile APP for their daily academic
                activities like student attendance, homeworks, exam. WYC
                provides a seamless integration of Bio-Metric device (cams-unit)
                for teacher attendance.
              </p>
            </Col>
          </Row>
          <Card className="box">
            <CardHeader>
              <h4> Attendance System and Biometric Integration </h4>
              Apart from this WYC provides features for teacher attendance,
              integration of Bio-Metric device for teacher attendance.
              <h5> The following Features are:</h5>
            </CardHeader>

            <CardBody>
              <h5>Marking Attendance:</h5>
              WYC provides a salient mobile app feature to take the class
              student attendance.
              <br />
              <br />
              <h5>Biometric Attendance and its integration:</h5>
              WYC provides a seamless integration of biometric where teachers
              attendance with their IN-TIME/OUT-TIME can be registered. <br />
              <strong className="strong">
                {" "}
                We are working on the student biometric attendance system for
                students as well.
              </strong>
              <br />
              <br />
              <h5>Bulk Upload of Student Attendance:</h5>
              WYC provides student bulk upload of attendance on daily basis on
              admin. It can be uploaded class-wise on the selection of a
              particular date.
              <br />
              <br />
              <h5>Attendance Report: </h5>
              WYC provides student attendance report daily report or date-range
              report. Class wise report and individual student report. WYC also
              produces a monthly attendance report of Employees. All attendance
              is visible over the APP where any individual teachers/student can
              see his attendance with their % attendance.
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Attendance;
