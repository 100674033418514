import React, {Component} from 'react';
import {Reveal} from 'react-reveal/';
import { BrowserRouter as Router,Link, Route, Switch } from "react-router-dom";
import jhonData from '../../component/jhonData';
import Contact from '../../../src/component/Contact';
// import { Homefrelencer } from '../../Home-frelencer';

class frelencerBanner extends Component {
    render(){
        return(
            <section className="frelencer_banner_area" id="home">
                <Reveal effect="fadeInRight" duration={500}>
                <img className="s_shap" src={require('../../image/Group17286.png')}  alt=""/>
                {/* <img className="s_shap" src={require('../../image/Group123.png')}  alt=""/> */}

                {/* <img className="" src="(src\image\finalImg\Group 17236.png)"  alt=""/> */}
                <div className="frelencer_img">
                   <img className="" src="('')"  alt=""/>    
                </div>               
                </Reveal>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 ">
                            <div className="frelencer_content">
                                {/* <Homefrelencer/> */}
                                <Reveal effect="fadeInUp" duration={500}>
                                    <h2 className="t_color"><strong>WYC DIGITAL / ERP Solutions</strong></h2>
                                    {/* <h3><strong>COMPLETE DIGITAL SCHOOL ERP SOLUTION & AUTOMATION</strong></h3><br /> */}
                                    <h5><strong>WYC is the School Management software intergrated with all the required modules of an Education institute, in one application, that to, with the most user friendly interface.</strong></h5><br />
                                </Reveal>
                                <Reveal effect="fadeInUp" duration={700}>
                                    <h4><span>Customize Software Solutions</span></h4>
                                </Reveal>
                                <Reveal effect="fadeInUp" duration={900}>
                                    <a href="/contactus"className="theme_btn active"><Route exact path="/contactus" render={(props) => <Contact jhonData={jhonData}/>} />Contact Us</a>   
                                </Reveal>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="frelencer_img">
                            <img className="" src="('')"  alt=""/>                       
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default frelencerBanner;
