import React from "react";
import emailjs from "emailjs-com";
import API from "../API";
import SweetAlert from '../component/SweetAlert';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetAlert: {
        show: false,
        msg: "",
        type: "", // success, error, warning, info
        title: "",
        // redirectUrl: "",
      },
    };
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name = value.length === 0 ? "Name is not empty" : "";
        break;
      case "subject":
        errors.subject = value.length < 5 ? "Subject must be 5 characters" : "";
        break;
      case "phone":
        errors.phone = value.length < 5 ? "phone is not empty" : "";
        break;
      case "email":
        errors.email = value.length < 5 ? "Subject is not empty" : "";
        let appos = value.indexOf("@");
        let dots = value.lastIndexOf(".");

        if (appos < 1 || dots - appos < 2) {
          errors.email = "please enter valid email";
        }

        break;

      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    // if (
    //   this.state.errors.name.length === 0 &&
    //   this.state.errors.subject.length === 0 &&
    //   this.state.errors.phone.length === 0 &&
    //   this.state.errors.email.length === 0
    // ) {
    //   alert("form is valid");
    // } else {
    //   alert("form is invalid");
    // }

    let body = {

      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      description: this.state.description,
      subject: this.state.subject,
      requestStatus: "New",
      schoolCount: this.state.schoolCount
      // schoolCount: this.state.",
      // requestStatus: this.state.New
    }
    API.postSchoolRegistration(body)
      .then((resp) => resp.json())
      .then((jsonResp) => {
        if (jsonResp.success) {
          const alert = {
            show: true,
            msg: 'School Request successfully.',
            type: 'success',
            title: 'Success',
            redirectUrl: '/school_registration',
          }
          this.setState({ sweetAlert: alert });
        } else {
          const alert = {
            show: true,
            msg: ' School Request Failed .',
            type: 'error',
            title: 'Error',
            redirectUrl: '/school_registration',
          }
          this.setState({ sweetAlert: alert });
        }
      })
  };

  handleConfirm = () => {
    const alert = {
      show: false,
    };
    this.setState({ sweetAlert: alert });
  };


  render() {
    const { errors, sweetAlert } = this.state;
    return (
      <React.Fragment>
        <form onSubmit={this.submitHandler.bind(this)} className="form_class">
          <div className="row">
            <div className="col-lg-4">
              <label className="School-Form">Full Name</label>{''}
              <span className="alidations">*</span>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                placeholder="School Name"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-lg-4">
              <label className="School-Form">Email</label>
              <span className="alidations">*</span>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email Id"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-lg-4">
              <label className="School-Form">Mobile Number</label>
              <span className="alidations">*</span>
              <input
                type="text"
                className="form-control"
                id="mobile"
                name="mobile"
                placeholder="Mobile Number"
                onChange={this.handleChange}
              />
            </div>
            <div className="col-lg-4">
              <label className="School-Form">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="form-control"
                placeholder="Subject"
                onChange={this.handleChange}
              />
            </div>


          </div>
          <textarea
            name="description"
            id="message"
            className="form-control"
            rows="6"
            placeholder="Description"
            onChange={this.handleChange}
          ></textarea>
          <button type="submit" className="btn send_btn theme_btn">
            Send Message
        </button>
        </form>

        <SweetAlert data={sweetAlert} handleConfirm={this.handleConfirm} />
      </React.Fragment>

    );
  }
}

export default Form;
