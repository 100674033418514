import React, { useEffect, useState } from 'react';
import API from '../../API';


const GoogleCaptcha = props => {

    const handleVerify = (data) => {
        console.log("captcha_data === ", data);
    }

    function verifyCaptchaToken(token, retries=5) {
       
    }

    function callCaptcha(retries=4) {
        try {
            if (window.grecaptcha) {
                let grecaptcha = window.grecaptcha;
                console.log("grecaptcha === ", grecaptcha);
                grecaptcha.ready(function (data) {
                    grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, { action: 'submit' }).then(function (token) {
                        // verifyCaptchaToken(token, 4);
                        API.verifyGoogleRecaptcha(token)
                        .then(response => {
                            console.log('verify_response === ', response);
                            if (response.success) {
                                if (props.captchaVerified && typeof props.captchaVerified === 'function') {
                                    props.captchaVerified(response.success);
                                }
                            }
                            else if(retries>0){
                                setTimeout(() => {
                                    callCaptcha(retries-1);
                                }, 5000);
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                    });
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        callCaptcha();
    }, []);

    return (
        <div class="g-recaptcha"
            data-sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            data-callback={handleVerify}
            data-size="invisible">
        </div>
    )
}

export default GoogleCaptcha;