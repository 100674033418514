import React, { Component } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal/";


class Footer extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <div>
        <section className="footer-area">
          <div className="container">
            <div className="footer-content">
              <div className="row">
                <div className="col-md-4">
                  <Link
                    to="/"
                    className="logo wow fadeInDown"
                    data-wow-delay="0.4s"
                  >
                    <img src={require("../image/logo-way.png")} alt="" />
                  </Link>
                  <h5>
                    Our game changing innovations are redefining the
                    possibilities of digital experiences in School Adminstration
                  </h5>
                  <Fade top cascade className="social">
                    <ul className="list_style">
                      {jhonData.socialLinks.map((item) => {
                        return (
                          <li key={item.name}>
                            <a href={item.url}>
                              <i className={item.className}></i>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </Fade>{" "}
                </div>

                {/*--------------------------footer link's-------------------------------------- */}

                <div className="col-md-4 link">
                  <header className="nave">Navigation</header>
                  <Link activeClass="active" to="/">
                    <h6>Home</h6>
                  </Link>
                  <Link
                    className=""
                    activeClass="active"
                    to="mission"
                  >
                    <h6>About us</h6>
                  </Link>

                  <Link className="" activeClass="active" to="pricing">
                    <h6>Pricing</h6>
                  </Link>
                  <Link
                    className=""
                    activeClass="active"
                    to="school_registration"
                  >
                    <h6>School Registration</h6>
                  </Link>
                  <Link className="" activeClass="active" to="contactus">
                    <h6>Contact Us</h6>
                  </Link>
                </div>

                <div className="col-md-4 " >
                  <header className="navi">Contact Info</header>
                  <p style={{ marginBottom: "-5px" }}>
                    Meerapur Basai Taktakpur Ekta Nagar Colony Varanasi Uttar
                    Pradesh IN - 221003
                  </p>
                  <p style={{ marginBottom: "-5px" }}><b>(For Technical & sale queries)</b></p>
                  <p style={{ marginBottom: "-5px" }}>Phone: +91-995-366-6077 <br /> +91-829-944-5763</p>
                  <p style={{ marginBottom: "-5px" }}>Whatapp:+91-9453047100 </p>
                  <p>Email: wayyourchild@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="row footer_align">
          <h6>Copyright © 2021 Way Your Child. All Rights Reserved</h6>
        </div>
      </div>
    );
  }
}
export default Footer;
