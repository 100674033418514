import React, { useEffect, useState } from "react";
import API from "../../API";
import { decodeSearchString } from "../../config/RouterConfig";
import { BloodGroupList, ReligionList, Gender } from "../DropDownContant"
import SweetAlert from '../../component/SweetAlert';
import { AvForm, AvInput, AvGroup, AvFeedback, AvField } from 'availity-reactstrap-validation';
import { Row, Col, Label, Button, Form, Card, CardTitle, CardText } from 'reactstrap';
import { CountryDropDown, NationalityDropdown, StateListComponent } from '../../commonComponents/countryState/countryStateSelector';
import GoogleCaptcha from "../../commonComponents/googleReCaptcha/GoogleReCaptcha";


const FirstComponent = (props) => {
    const [classList, setClassList] = useState([]);
    const [feeCategoryList, setFeeCategoryList] = useState([]);
    const [casteCategoryList, setCasteCategoryList] = useState([]);
    const [sessionList , setSessionList] = useState([]); 
    

    const getClassList = (schoolId) => {
        API.listSettingBySchoolId('class-setting', schoolId)
            .then(res => res.json())
            .then(jsonRes => {
                if (jsonRes.success && jsonRes.data && jsonRes.data.length) {
                    setClassList(jsonRes.data.sort((a, b) => {
                        if (+a.order < +b.order) {
                            return -1;
                        }
                        if (+a.order > +b.order) {
                            return 1;
                        }
                        return 0;
                    }));
                } else if (jsonRes.errors) {
                    alert(jsonRes.errors.message)
                }
            }
            )

        API.listSettingBySchoolId('caste-category-setting', schoolId)
            .then(res => res.json())
            .then(jsonRes => {
                if (jsonRes.success && jsonRes.data && jsonRes.data.length) {
                    setCasteCategoryList(jsonRes.data);
                } else if (jsonRes.errors) {
                    alert(jsonRes.errors.message)
                }
            }
            )

        API.listSettingBySchoolId('fee-category-setting', schoolId)
            .then(res => res.json())
            .then(jsonRes => {
                if (jsonRes.success && jsonRes.data && jsonRes.data.length) {
                    setFeeCategoryList(jsonRes.data);
                } else if (jsonRes.errors) {
                    alert(jsonRes.errors.message)
                }
            }
            )

            API.listSettingBySchoolId('session-setting', schoolId)
            .then(res => res.json())
            .then(jsonRes => {
                if (jsonRes.success && jsonRes.data && jsonRes.data.length) {
                    setSessionList(jsonRes.data);
                } else if (jsonRes.errors) {
                    alert(jsonRes.errors.message)
                }
            }
            )

    }

    useEffect(() => {
        let search = window.location.hash.split("?")[1];
        let schoolId = decodeSearchString("schoolId", search);
        getClassList(schoolId)
       
    }, [])

    return (
        <div className="">
            <div className="row">
                <Col lg="3" >
                    <AvGroup>
                        <label className="student-lebel">First Name </label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="text"
                            id="firstName"
                            name="firstName"
                            onChange={props.handleChange}
                            placeholder="First Name"
                            required
                        />
                        {/* <AvFeedback>First name can not be empty</AvFeedback> */}
                    </AvGroup>

                </Col>
                <Col lg="3" lg="3">
                    <AvGroup>
                        <label className="student-lebel">Middle Name </label>{''}
                        <AvInput
                            type="text"
                            id="middleName"
                            name="middleName"
                            onChange={props.handleChange}
                            placeholder="Middle Name"
                        />
                    </AvGroup>
                </Col>
                <Col lg="3">
                    <AvGroup>
                        <label className="student-lebel">Last Name </label>{''}
                        <AvInput
                            type="text"
                            id="lastName"
                            name="lastName"
                            onChange={props.handleChange}
                            placeholder="Last Name"
                        />
                    </AvGroup>
                </Col>
                <Col lg="3">
                    <AvGroup>
                        <label className="student-lebel">Mobile Number </label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="number"
                            id="mobileNo"
                            name="mobileNumber"
                            onChange={props.handleChange}
                            placeholder="Mobile Number"
                            required={true}
                            maxLength={10}
                            minLength={10}
                        />
                        {/* <AvFeedback>Invalid mobile number</AvFeedback> */}
                    </AvGroup>
                </Col>
                <Col lg="3">
                    <AvGroup>
                        <label className="student-lebel">Date of Birth </label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="Date"
                            id="dob"
                            name="dob"
                            onChange={props.handleChange}
                            placeholder="Dob"
                            required={true}
                        />
                        {/* <AvFeedback>Invalid DOB</AvFeedback> */}
                    </AvGroup>
                </Col>
                <Col lg="3">
                    <AvGroup>
                        <label className="student-lebel">Email </label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="email"
                            id="email"
                            name="email"
                            onChange={props.handleChange}
                            placeholder="Email Id"
                            required={true}
                        />
                        {/* <AvFeedback>Invalid email</AvFeedback> */}
                    </AvGroup>
                </Col>
                <Col lg="3">
                    <label className="student-lebel">Gender</label>{''}
                    <span className="valid">*</span>
                    <AvField
                        // className="form-control"
                        id="gender"
                        name="gender"
                        type="select"
                        onChange={props.handleChange}
                        required={true}
                    >
                        <option value=''>Select</option>
                        {Gender && Gender.map(item => {
                            return <option value={item}>{item}</option>
                        })}
                        {/* <AvFeedback>Invalid field</AvFeedback> */}
                    </AvField>
                </Col>
                <Col lg="3">
                    <label className="student-lebel">BloodGroup </label>{''}
                    <select
                        className="form-control"
                        id="bloodGroup"
                        name="bloodGroup"
                        onChange={props.handleChange}
                        required={true}
                    >
                        <option value=''>Select</option>
                        {BloodGroupList && BloodGroupList.map(item => {
                            return <option value={item}>{item}</option>
                        })}
                    </select>
                </Col>
                <Col lg="3">
                    <label className="student-lebel">Nationality</label>{''}
                    <span className="valid">*</span>
                    <NationalityDropdown
                        name="nationality"
                        onChange={props.handleChange}
                        required
                    />
                </Col>
                <Col lg="3">
                    <label className="student-lebel">Religion </label>{''}
                    <span className="valid">*</span>
                    {<select
                        className="form-control"
                        id="religion"
                        name="religion"
                        ReligionList={ReligionList}
                        onChange={props.handleChange}
                        required={true}
                    >
                        <option value=''>Select</option>
                        {ReligionList && ReligionList.map(item => {
                            return <option value={item}>{item}</option>
                        })}

                    </select>}
                </Col>
                <Col lg="3">
                    <label className="student-lebel">FeeCategory</label>{''}
                    <span className="valid">*</span>
                    <select
                        className="form-control"
                        id="feeCategoryId"
                        name="feeCategoryId"
                        onChange={props.handleChange}
                        required={true}
                    >
                        <option value=''>Select</option>
                        {feeCategoryList && feeCategoryList.map(item => {
                            return <option value={item._id}>{item.name}</option>
                        })}
                    </select>
                </Col>

                <Col lg="3">
                    <label className="student-lebel">CasteCategory</label>{''}
                    <span className="valid">*</span>
                    <select
                        className="form-control"
                        id="casteCategoryId"
                        name="casteCategoryId"
                        onChange={props.handleChange}
                        required={true}
                    >
                        <option value=''>Select</option>
                        {casteCategoryList && casteCategoryList.map(item => {
                            return <option value={item._id}>{item.name}</option>
                        })}
                    </select>
                </Col>
                <Col lg="3">
                    <AvGroup>
                        <label className="student-lebel">Aadhar Number</label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="number"
                            className="form-control"
                            id="aadharNumber"
                            onChange={props.handleChange}
                            name="aadharNumber"
                            placeholder="AadharNumber"
                            required={true}
                            maxLength={12}
                            minLength={12}
                        />
                    </AvGroup>
                </Col>
                <Col lg="3">
                    <label className="student-lebel">Class </label>{''}
                    <span className="valid">*</span>
                    <select
                        type="text"
                        className="form-control"
                        id="classId"
                        name="classId"
                        onChange={props.handleChange}
                        required={true}
                    >
                        <option value=''>Select</option>
                        {classList && classList.map(item => {
                            return <option value={item._id}>{item.name}</option>
                        })}

                    </select>
                </Col>
                <Col lg="3">
                    <label className="student-lebel">Session </label>{''}
                    <span className="valid">*</span>
                    <select
                        type="text"
                        className="form-control"
                        id="sessionId"
                        name="sessionId"
                        onChange={props.handleChange}
                        required={true}
                    >
                        <option value=''>Select</option>
                        {sessionList && sessionList.map(item => {
                            return <option value={item._id}>{item.name}</option>
                        })}

                    </select>
                </Col>
            </div>
        </div>
    );
};

const SecondComponent = (props) => {
    return (
        <div className="form_class">
            <div className="row">
                <Col lg="3" >
                    <AvGroup>
                        <label className="student-lebel">Father Name </label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="text"
                            id="fathername"
                            name="fName"
                            onChange={props.handleChange}
                            className="form-control"
                            placeholder="Father Name"
                            required={true}
                        />
                        {/* <AvFeedback>First name can not be empty</AvFeedback> */}
                    </AvGroup>
                </Col>

                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Mobile Number</label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="number"
                            className="form-control"
                            id="fPrimaryNumber"
                            name="fPrimaryNumber"
                            onChange={props.handleChange}
                            placeholder="Primary Number"
                            required={true}
                        />
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Qualification</label>{''}
                        <AvInput
                            type="text"
                            id="Qualification"
                            name="fQualification"
                            className="form-control"
                            onChange={props.handleChange}
                            placeholder="Qualification"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Occupation</label>{''}
                        <AvInput
                            type="text"
                            className="form-control"
                            id="fOccupation"
                            name="fOccupation"
                            onChange={props.handleChange}
                            placeholder="Occupation"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Office Address</label>{''}
                        <AvInput
                            type="text"
                            id="fOfficeAddress"
                            name="fOfficeAddress"
                            className="form-control"
                            onChange={props.handleChange}
                            placeholder="Office Address"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Aadhar Number</label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="number"
                            className="form-control"
                            id="faadharNumber"
                            name="fAadharNumber"
                            onChange={props.handleChange}
                            placeholder="Aadhar Number"
                            required={true}
                        />
                        <p></p>
                    </AvGroup>
                </Col>

            </div>
            <hr />
            <div className="row">
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Mother Name </label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="text"
                            id="mothername"
                            name="mName"
                            className="form-control"
                            onChange={props.handleChange}
                            placeholder="Mother Name"
                            required={true}
                        />
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Mobile Number</label>{''}
                        <AvInput
                            type="number"
                            className="form-control"
                            id="mPrimaryNumber"
                            name="mPrimaryNumber"
                            onChange={props.handleChange}
                            placeholder="Primary Number"
                        />
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Qualification</label>{''}
                        <AvInput
                            type="text"
                            id="mQualification"
                            name="mQualification"
                            className="form-control"
                            onChange={props.handleChange}
                            placeholder="Qualification"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Occupation </label>{''}
                        <AvInput
                            type="text"
                            className="form-control"
                            id="mOccupation"
                            name="mOccupation"
                            onChange={props.handleChange}
                            placeholder="Occupation"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Office Address </label>{''}
                        <AvInput
                            type="text"
                            id="mOfficeAddress"
                            name="mOfficeAddress"
                            className="form-control"
                            onChange={props.handleChange}
                            placeholder="Office Address"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Aadhar Number</label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="number"
                            className="form-control"
                            id="maadharNumber"
                            name="mAadharNumber"
                            onChange={props.handleChange}
                            placeholder="Aadhar Number"
                            required={true}
                        />
                        <p></p>
                    </AvGroup>
                </Col>

            </div>
            <hr />
            <div className="row">
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Guardian Name </label>{''}
                        <AvInput
                            type="text"
                            id="guardianName"
                            name="gName"
                            onChange={props.handleChange}
                            className="form-control"
                            placeholder="Guardian Name"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Mobile Number</label>{''}
                        <AvInput
                            type="number"
                            className="form-control"
                            id="fPrimaryNumber"
                            name="fPrimaryNumber"
                            onChange={props.handleChange}
                            placeholder="Primary Number"
                        />
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Relation </label>{''}
                        <AvInput
                            type="text"
                            className="form-control"
                            id="gRelation"
                            name="gRelation"
                            onChange={props.handleChange}
                            placeholder="Relation"
                        />
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Qualification</label>{''}
                        <AvInput
                            type="text"
                            id="gQualification"
                            name="gQualification"
                            onChange={props.handleChange}
                            className="form-control"
                            placeholder="Qualification"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Occupation</label>{''}
                        <AvInput
                            type="text"
                            className="form-control"
                            id="gOccupation"
                            name="gOccupation"
                            onChange={props.handleChange}
                            placeholder="Occupation"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Office Address</label>{''}
                        <AvInput
                            type="text"
                            id="gOfficeAddress"
                            name="gOfficeAddress"
                            className="form-control"
                            onChange={props.handleChange}
                            placeholder="Office Address"
                        />
                        <p></p>
                    </AvGroup>
                </Col>

                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Aadhar Number</label>{''}
                        <AvInput
                            type="number"
                            className="form-control"
                            id="gaadharNumber"
                            name="gAadharNumber"
                            onChange={props.handleChange}
                            placeholder="Aadhar Number"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
            </div>
        </div>
    );
};

const ThirdComponent = (props) => {
    const [countryName, setCountryName] = useState('');


    const handleCountryChange = (e) => {
        let countryName = e.target.value;
        setCountryName(countryName);
        if (props.handleChange && typeof props.handleChange === 'function') {
            props.handleChange(e);
        }
    }

    return (
        <div className="form_class">
            <div className="row">
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Line 1</label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="text"
                            id="line1"
                            name="line1"
                            onChange={props.handleChange}
                            className="form-control"
                            placeholder="Line 1"
                            required={true}
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Line 2</label>{''}
                        <AvInput
                            type="text"
                            id="line2"
                            name="line2"
                            onChange={props.handleChange}
                            className="form-control"
                            placeholder="Line 2"
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">City</label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            onChange={props.handleChange}
                            placeholder="City"
                            required={true}
                        />
                        <p></p>
                    </AvGroup>
                </Col>
                <Col className="col-lg-3">
                    <label className="student-lebel">Country</label>{''}
                    <span className="valid">*</span>
                    <CountryDropDown
                        name="country"
                        onChange={handleCountryChange}
                        required={true}
                        label="Country"
                    />
                </Col>
                <Col className="col-lg-3">
                    <label className="student-lebel">State </label>
                    <span className="valid">*</span>
                    <StateListComponent
                        countryName={countryName}
                        name="state"
                        onChange={handleCountryChange}
                        required={true}
                        label="State"
                    />
                </Col>
                <Col className="col-lg-3">
                    <AvGroup>
                        <label className="student-lebel">Pincode</label>{''}
                        <span className="valid">*</span>
                        <AvInput
                            type="number"
                            className="form-control"
                            id="pinCode"
                            name="pinCode"
                            onChange={props.handleChange}
                            placeholder="Pincode"
                            required={true}
                        />
                    </AvGroup>
                </Col>
            </div>
        </div >
    );
};

function StudentRegistration() {

    const [StepValue, setStepValue] = useState({});
    const [sessionId, setSessionId] = useState('');

    useEffect(() => {
        const storedSessionId = localStorage.getItem('localSessionId');

        if (storedSessionId) {
            setSessionId(storedSessionId);
        }
    }, [])

    const [alertData, setAlertData] = useState({
        show: false,
        msg: "",
        type: "",
        title: "",
    });

    const handleConfirm = () => {
        setAlertData({
            show: false,
            msg: "",
            type: "",
            title: "",
        })
    }

    const handleCountryChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            setStepValue({ [name]: value });
        }
    }

    const handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            setStepValue(prevState => ({ ...prevState, [name]: value }))
        }
    }

    const captchaVerified = (resp) => {
        console.log("captchaVerified === ", resp);
    }

    const [steps, setSteps] = useState([

        {
            key: "firstStep",
            label: "Student Info",
            isDone: true,
            component: () => {
                return <FirstComponent handleChange={handleChange} StepValue={StepValue} BloodGroupList={BloodGroupList} ReligionList={ReligionList} />
            },
        },
        {
            key: "secondStep",
            label: "Parents Info",
            isDone: false,
            component: () => { return <SecondComponent handleChange={handleChange} StepValue={StepValue} /> },
        },
        {
            key: "thirdStep",
            label: "Address Info",
            isDone: false,
            component: () => { return <ThirdComponent handleChange={handleChange} handleCountryChange={handleCountryChange} StepValue={StepValue} /> },
        },
    ]);

    useEffect(() => { console.log("ffirststepvalue", StepValue) }, [StepValue])

    const [activeStep, setActiveStep] = useState(steps[0]);


    const submit = () => {
        const payload = {
            firstName: StepValue.firstName,
            middleName: StepValue.middleName,
            lastName: StepValue.lastName,
            fullName: StepValue.firstName  + (StepValue.middleName ? ' ' + StepValue.middleName : '') + ' ' +StepValue.lastName,
            mobileNumber: StepValue.mobileNumber,
            dob: StepValue.dob,
            gender: StepValue.gender,
            email: StepValue.email,
            bloodGroup: StepValue.bloodGroup,
            nationality: StepValue.nationality,
            religion: StepValue.religion,
            casteCategoryId: StepValue.casteCategoryId,
            aadharNumber: StepValue.aadharNumber,
            feeCategoryId: StepValue.feeCategoryId,
            classId: StepValue.classId,
            sessionId: StepValue.sessionId,
            address: {
                line1: StepValue.line1,
                line2: StepValue.line2,
                city: StepValue.city,
                state: StepValue.state,
                country: StepValue.country,
                pinCode: StepValue.pinCode,
                addressType: 'Permanent',
            },
            parentInfo: {
                father: {
                    name: StepValue.fName,
                    primaryNumber: StepValue.fPrimaryNumber,
                    secondaryNumber: StepValue.fSecondaryNumber,
                    qualification: StepValue.fQualification,
                    occupation: StepValue.fOccupation,
                    officeAddress: StepValue.fOfficeAddress,
                    aadharNumber: StepValue.fAadharNumber,
                },
                mother: {
                    name: StepValue.mName,
                    primaryNumber: StepValue.mPrimaryNumber,
                    secondaryNumber: StepValue.mSecondaryNumber,
                    qualification: StepValue.mQualification,
                    occupation: StepValue.mOccupation,
                    officeAddress: StepValue.mOfficeAddress,
                    aadharNumber: StepValue.mAadharNumber,
                }
            },
            guardianInfo: {
                name: StepValue.gName,
                relation: StepValue.gRelation,
                primaryNumber: StepValue.gPrimaryNumber,
                secondaryNumber: StepValue.gSecondaryNumber,
                email: StepValue.gEmail,
                address: StepValue.gAddress,
            },
        }
        let search = window.location.hash.split("?")[1];
        let schoolId = decodeSearchString("schoolId", search);
        API.postStudentRegistration(schoolId, payload)
            .then((resp) => resp.json())
            .then((jsonRes) => {
                if (jsonRes.responseCode === 200) {
                    setAlertData({
                        show: true,
                        msg: "Student Registration successfully.",
                        type: "success",
                        title: "Success",
                        redirectUrl: `payNow?regId=${jsonRes.result._id}`
                    })
                } else if (jsonRes.errors) {
                    setAlertData({
                        show: true,
                        msg: jsonRes.errors.message,
                        type: "error",
                        title: "Error",
                    })
                } else {
                    setAlertData({
                        show: true,
                        msg: "Something want wrong.",
                        type: "error",
                        title: "Error",
                    })
                }
            });
    }


    const handleNext = (e) => {
        e.preventDefault();
        if (document) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
        if (steps[steps.length - 1].key === activeStep.key) {
            submit();
            return;
        }

        const index = steps.findIndex((x) => x.key === activeStep.key);
        setSteps((prevStep) =>
            prevStep.map((x) => {
                if (x.key === activeStep.key) x.isDone = true;
                return x;
            })
        );
        setActiveStep(steps[index + 1]);
    };

    const handleBack = () => {
        if (document) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
        const index = steps.findIndex((x) => x.key === activeStep.key);
        if (index === 0) return;

        setSteps((prevStep) =>
            prevStep.map((x) => {
                if (x.key === activeStep.key) x.isDone = false;
                return x;
            })
        );
        setActiveStep(steps[index - 1]);
    };



    return (
        <div className="App">
            {/* <h4>
        Step wizard in React -{" "}
        <a
          href="https://www.cluemediator.com"
          title="Clue Mediator"
          target="_blank"
          rel="nofollow"
        >
          Clue Mediator
        </a>
      </h4> */}
            <div className="box">
                <div className="steps">
                    <ul className="nav">
                        {steps.map((step, i) => {
                            return (
                                <li
                                    key={i}
                                    className={`${activeStep.key === step.key ? "active" : ""} ${step.isDone ? "done" : ""
                                        }`}
                                >
                                    <div>
                                        Step {i + 1}
                                        <br />
                                        <span>{step.label}</span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                {steps.map((step, i) => {
                    return (
                        <>
                            <AvForm onSubmit={(e, errors, values) => {
                                // if (errors.length) {
                                //     e.preventDefault();
                                // }
                                // else {
                                handleNext(e);
                                // }
                            }}>
                                <div className="step-component" style={{ display: activeStep.key == step.key ? "block" : "none" }}>{step.component()}</div>
                                <div className="btn-component" style={{ display: activeStep.key == step.key ? '' : "none" }}>
                                    <input
                                        style={{
                                            width: "120px",
                                            border: "none ",
                                            borderRadius: "10px",
                                            color: "white",
                                            backgroundColor: "#0db3c7",
                                        }}
                                        type="button"
                                        value="Prev"
                                        onClick={handleBack}
                                        disabled={steps[0].key === activeStep.key}
                                    />
                                    <input
                                        className="btnn"
                                        type="submit"
                                        value={
                                            steps[steps.length - 1].key !== activeStep.key ? "Next" : "Submit"
                                        }
                                    // onClick={handleNext}
                                    />
                                </div>
                                {
                                    steps[steps.length - 1].key === activeStep.key ?
                                        <>
                                            <Row>
                                                <Col lg="3">
                                                    <GoogleCaptcha captchaVerified={captchaVerified} />
                                                </Col>
                                            </Row>
                                        </>
                                        : null
                                }
                            </AvForm>
                        </>
                    )
                })}

            </div>
            <SweetAlert data={alertData} handleConfirm={handleConfirm} />
        </div >
    );
}

export default StudentRegistration;
