import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Input, Label } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";

const PayuPaymentForm = () => {
  const location = useLocation();
  const history = useHistory();
  const formContainerRef = useRef(null);
  const [paymentDetails, setPaymentDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    amount: "",
    productInfo: "",
    txnId: "",
  });

  useEffect(() => {
    // Try to get form data from different sources
    let formHtml;

    // Get form data from location state (React Router v5 format)
    if (location.state && location.state.info) {
      formHtml = location.state.info;
    } else {
      // Fallback to query parameters if state is not available
      const queryParams = new URLSearchParams(location.search);
      const infoParam = queryParams.get("info");
      if (infoParam) {
        try {
          formHtml = JSON.parse(decodeURIComponent(infoParam));
        } catch (error) {
          console.error("Error parsing query parameter:", error);
          // history.push("/Error");
          return;
        }
      } else {
        console.error("Form data not found in state or query parameters");
        // history.push("/Error");
        return;
      }
    }

    if (!formContainerRef.current || !formHtml) {
      console.error("Form container ref or form HTML is missing");
      history.push("/Error");
      return;
    }

    // Clear previous content
    formContainerRef.current.innerHTML = "";

    // Create a temporary div to parse the HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = formHtml;

    // Parse form data
    const parseFormData = (htmlString) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");
      const form = doc.querySelector("form");

      if (!form) {
        console.error("No form found in HTML");
        history.push("/Error");
        return null;
      }

      const formInputs = form.querySelectorAll("input");
      const data = {};

      formInputs.forEach((input) => {
        data[input.name] = input.value;
      });

      return {
        firstName: data.firstname || data.firstName || "",
        lastName: data.lastname || data.lastName || "",
        email: data.email || "",
        mobile: data.phone || data.mobile || "",
        amount: data.amount || "",
        productInfo: data.productinfo || data.productInfo || "",
        txnId: data.txnid || data.txnId || "",
      };
    };

    try {
      // Extract payment details
      const details = parseFormData(formHtml);
      if (details) {
        setPaymentDetails(details);

        // Extract and append the form element
        const formElement = tempDiv.querySelector("form");
        if (formElement) {
          formContainerRef.current.appendChild(formElement);
        } else {
          throw new Error("Form element not found in parsed HTML");
        }
      } else {
        throw new Error("Failed to parse form data");
      }
    } catch (error) {
      console.error("Error processing form data:", error);
      history.push("/Error");
    }
  }, [location, history]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Also update the hidden form
    const hiddenForm = formContainerRef.current.querySelector("form");
    if (hiddenForm) {
      const hiddenInput = hiddenForm.querySelector(
        `input[name="${name}"], input[name="${name.toLowerCase()}"]`
      );
      if (hiddenInput) {
        hiddenInput.value = value;
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formElement = formContainerRef.current.querySelector("form");
    if (formElement) {
      // Update all values in hidden form before submission
      Object.entries(paymentDetails).forEach(([key, value]) => {
        const inputName = key.toLowerCase();
        const input = formElement.querySelector(`input[name="${inputName}"]`);
        if (input) {
          input.value = value;
        }
      });

      formElement.submit();
    } else {
      console.error("Payment form not found");
      history.push("/Error");
    }
  };

  return (
    <>
      <div>
        <section className="contact-area" id="contact">
          <div className="container">
            <div className="row row-reverse">
              <div className="container mx-auto px-4 py-8">
                <Card className="w-full max-w-md mx-auto p-6 shadow-lg" style={{padding:'2rem'}}>
                  <div className="text-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">
                      Payment Details
                    </h2>
                  </div>

                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <Label
                          htmlFor="firstName"
                          className="text-sm font-medium"
                        >
                          First Name
                        </Label>
                        <Input
                          id="firstName"
                          name="firstName"
                          value={paymentDetails.firstName}
                          onChange={handleInputChange}
                          required
                          className="w-full"
                        />
                      </div>
                      <div className="space-y-2">
                        <Label
                          htmlFor="lastName"
                          className="text-sm font-medium"
                        >
                          Last Name
                        </Label>
                        <Input
                          id="lastName"
                          name="lastName"
                          value={paymentDetails.lastName}
                          onChange={handleInputChange}
                          required
                          className="w-full"
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="email" className="text-sm font-medium">
                        Email
                      </Label>
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        value={paymentDetails.email}
                        onChange={handleInputChange}
                        required
                        className="w-full"
                      />
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="mobile" className="text-sm font-medium">
                        Mobile
                      </Label>
                      <Input
                        id="mobile"
                        name="mobile"
                        type="tel"
                        value={paymentDetails.mobile}
                        onChange={handleInputChange}
                        required
                        className="w-full"
                      />
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="amount" className="text-sm font-medium">
                        Amount (₹)
                      </Label>
                      <Input
                        id="amount"
                        name="amount"
                        type="number"
                        value={paymentDetails.amount}
                        onChange={handleInputChange}
                        required
                        className="w-full"
                        disabled
                      />
                    </div>

                    <div className="space-y-2">
                      <Label
                        htmlFor="productInfo"
                        className="text-sm font-medium"
                      >
                        Product Info
                      </Label>
                      <Input
                        id="productInfo"
                        name="productInfo"
                        value={paymentDetails.productInfo}
                        onChange={handleInputChange}
                        required
                        className="w-full"
                        disabled
                      />
                    </div>

                    <div className="space-y-2">
                      <Label htmlFor="txnId" className="text-sm font-medium">
                        Transaction ID
                      </Label>
                      <Input
                        id="txnId"
                        name="txnId"
                        value={paymentDetails.txnId}
                        onChange={handleInputChange}
                        required
                        className="w-full"
                        readOnly
                      />
                    </div>

                    <div className="pt-6">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-full py-2 text-white font-medium rounded-md hover:bg-blue-600 transition-colors mt-2"
                      >
                        Proceed to Payment
                      </Button>
                    </div>
                  </form>

                  <div ref={formContainerRef} className="hidden" />
                </Card>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default PayuPaymentForm;