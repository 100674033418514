import React, { Component } from "react";
import image from "../../image/allCont/all9.png";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class Transport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Row className="class">
            <Col>Transport Management</Col>
          </Row>
          <Row>
            <Col className="image">
              <img className="size" src={image}></img>
            </Col>
          </Row>
          <Row>
            <Col className="content">
              <p>
                We provide complete cloud solution for School Transport
                Management. A complete android mobile application to get the
                status and tracking of school buses to ensure safety and
                security for all the students and every updates and details to
                parents. School can manage the transport fees as well. Following
                are the featured details:
              </p>
            </Col>
          </Row>
          <Card className="box">
            <CardHeader>
              <h4>Transport Management & Transport Fees</h4>Complete solution
              for transport management and integrated Mobile app solution for
              real time vehicle tracking & stoppages notifications mechanism
              along with transport details. Transport fees and its online
              payment are also integrated with mobile app through seamless
              PAYUBIZ payment gateway.
              <h5> The following Features are:</h5>
            </CardHeader>

            <CardBody>
              <h5>Transport Stoppage:</h5>
              Definition of any stoppages based on google Map API. You can
              define any location of stoppage where the bus actually stops based
              on lat/long.
              <br />
              <br />
              <h5>Route Definition:</h5>
              WYC provides a platform where one can define the Route of Vehicle
              according to the order of stoppages of Drop and pick up of
              vehicle.
              <br />
              <br />
              <h5>Fuel Consumption:</h5>
              WYC provides fuel consumption of each vehicle and its report.
              Report actually exhibits the vehicle no, meter reading, date of
              fuel entry, amount of Fuel consumption.
              <br />
              <br />
              <h5>Driver/Conductor Profile:</h5>
              WYC provides to maintain the profile of driver and also linking of
              mobile no and tracking of Vehicle based on IMEI no of Mobile
              provided by school.
              <br />
              <br />
              <h5>Transport Fees</h5>
              WYC provides a framework of maintaining the transport fees of each
              and every student assigned for Transport. It provides where one
              can define the expense based on Stoppages defined with two-way and
              one-way (pickup or drop). It also provides the automatic invoice
              generation on the given date on school level. Transport fees can
              be paid through mobile app online payment which is integrated
              <strong className="strong">
                {" "}
                with Payu-Biz payment gateway.
              </strong>{" "}
              Transport fees report can be pull any time regarding due fees and
              also report can be generated based on Class and section.
              <br />
              <br />
              <h5>Tracking:</h5>
              WYC also provides It is most salient feature of WYC. It provides a
              visual view of running buses in route to school, off Route bus
              tracking and idle buses for school administration tracking
              purpose. WYC provides an Android app too in which parents/teachers
              can track the real time buses position. Also provides a visual
              representation in APP for running buses in school route. This
              features ensure the safety and security of each student and any
              parent can track their child at any point of time. It shows real
              time status of buses on the stoppages covered by bus and also
              signifies whether the bus has been reached to the school or not.
              Notifications mechanism integrated with tracking of buses.
              Application send notification to teachers/parents when the bus
              starts and when the bus reach at the stoppages as well.
              <br />
              <br />
              <h5>Enable/Disable Transport:</h5>
              Any person can request for disabling the transport, if anyone does
              not want transport any more. Enabling of Transport can only be
              done by School. School level Transport Enable / disable is also
              provided by WYC.
              <br />
              <br />
              <h5>Transport Notification:</h5>
              This is very beautiful feature in transport provided. School Admin
              can send notification to all the related students/teachers based
              on stoppages, based on routes selected, based on a particular bus
              any time. This ensure the right and spontaneous communication to
              every individual. Transport overdue fees messages can be sent for
              reminder to the concerned parents as well.
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Transport;
