import React, { useEffect, useState } from "react";

import {
  AvFeedback,
  AvGroup,
  AvInput,
  AvField,
} from "availity-reactstrap-validation";

import { Label } from "reactstrap";

import { getList } from 'npm-nationality-list';

import { getCountries, getStates, getCountry } from "country-state-picker/lib/index";
let CountryList = getCountries();

export const StateListComponent = (props) => {

  const [statesList, setStatesList] = useState([])

  const { countryName } = props;

  useEffect(() => {
    let countryObject = getCountry(countryName);
    if (countryObject) {
      let statesList = getStates(countryObject.code);
      setStatesList(statesList);
    }
  }, [countryName]);

  return (
    <>
      <AvGroup id="statesContainer">
        <AvField
          style={{ textTransform: "uppercase" }}
          id="statesListField"
          type="select"
          name={props.name ? props.name : "state"}
          onChange={props.onChange}
          required={props.required}
          defaultValue={props.defaultValue}
        >
          <option value="">Select {props.label}</option>

          {statesList && statesList.length
            ? statesList.map((state, stateIndex) => {
              return (
                <option key={stateIndex} value={state}>
                  {state}
                </option>
              );
            })
            : props.defaultValue && (
              <option key={props.defaultValue} value={props.defaultValue}>
                {props.defaultValue}
              </option>
            )}
        </AvField>
        <AvFeedback>Please enter valid State</AvFeedback>
      </AvGroup>
    </>
  );
};

export const CountryDropDown = (props) => {
  return (
    <AvGroup>
      <AvField
        style={{ textTransform: "uppercase" }}
        defaultValue={props.defaultValue}
        // value={props.value}
        type="select"
        name={props.name ? props.name : "country"}
        onChange={props.onChange}
        required={props.required}
      >
        <option value="">
          Select {props.label}
        </option>
        {CountryList.length &&
          CountryList.map((item, index) => (
            <option key={item.code} value={item.name}>
              {item.name}
            </option>
          ))}
      </AvField>
      <AvFeedback>Please enter valid Country</AvFeedback>
    </AvGroup>
  );
};


export const NationalityDropdown = props => {

  const list = getList();

  return (
      <>
          <AvGroup>
              <AvField
                  style={{ textTransform: "uppercase" }}
                  defaultValue={props.defaultValue}
                  // value={props.value}
                  type="select"
                  name={props.name ? props.name : "nationality"}
                  onChange={props.onChange}
                  required={props.required}
              >
                  <option value="">
                      Select {props.label}
                  </option>
                  {list.length &&
                      list.map((item, index) => (
                          <option key={item.num_code} value={item.nationality}>
                              {item.nationality}
                          </option>
                      ))}
              </AvField>
              <AvFeedback>Please select nationality</AvFeedback>
          </AvGroup>
      </>
  )
}