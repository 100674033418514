import React, { Component } from "react";
class BlogTwoitems extends Component {
  render() {
    let { bTitle, bDetails, btnText, image } = this.props;
    return (
      <div className="col-lg-4 col-md-4">
        <div className="blog_post">
          <div className="blog_img">
            <img
              className="img-fluid"
              src={require("../../image/" + image)}
              alt=""
            />
          </div>
          <div className="post_content">
            {/* <div className="blog-meta">
                            <span>
                                <i className="icon_tags_alt"></i>
                                BY : Tasnim
                            </span>
                            <span>
                                <i className="icon_chat_alt"></i>
                                <a href="/#">0 comment</a>
                            </span>
                        </div> */}
            <a href="#/mission">
              <h2>{bTitle}</h2>
            </a>
            <p>
              {bDetails}
              <a href="#/mission" type="button" className="read">
                <span>Read more &#8594;</span>
              </a>
            </p>
            {/* <a href="/#" className="read_btn">{btnText}<i className="arrow_right"></i> </a> */}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogTwoitems;
