export const ReligionList = [
    'Hindu',
    'Muslim',
    'Sikh',
    'Jain',
    'Buddhist',
    'Christians',
    'Others'
];

export const BloodGroupList = [
    'O+',
    'O-',
    'A+',
    'A-',
    'B+',
    'B-',
    'AB+',
    'AB-'
];

export const Gender = [
    'Male',
    'Female',
]