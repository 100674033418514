import React, { Component } from "react";
import image from "../../image/allCont/all8.png";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class Exam extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Row className="class">
            <Col>Academic Performance & Exam Management</Col>
          </Row>
          <Row>
            <Col className="image">
              <img className="size" src={image}></img>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col className="content">
              <p>
                WYC provides a very systematic and very enhanced Exam management
                features. Exam Patterns for whole session can be defined and
                mapped at each class and section level.
              </p>
            </Col>
          </Row>
          <Card className="box">
            <CardHeader>
              <h4> EXAM SOLUTIONS AND MANAGEMENT </h4>
              Apart from this WYC provides features for exam scheduling, marks
              allocation, publishing exam, report generation and automatic
              mark-sheet generation in given format.
              <h5> The following Features are:</h5>
            </CardHeader>

            <CardBody>
              <h5>Exam Structure:</h5>
              WYC provides a very systematic and very enhanced Exam management
              features. Exam Pattern for whole session can be defined and mapped
              at class and section level. In this once can define the exam
              structure with different type of exams. One can also define
              whether the school follow grade system or marks system. In
              defining the exam structure we can define the passing marks.
              <br />
              <br />
              <h5>Exam Schedule:</h5>
              WYC provides a very seamless procedure to define the exam schedule
              and its classical way of representation in mobile app.
              <br />
              <br />
              <h5>Exam Mark Allocation:</h5>
              WYC provides facility to provide marks at exam level and also its
              integration with mobile app for a teacher to provide the marks and
              submit through app.
              <br />
              <br />
              <h5>Exam Report: </h5>
              WYC provides a classical exam report and its representation in
              mobile app. WYC produce the exam report based on marks, weightage
              and also define the rank at class and section level. It also
              produces the report taking grace mark into consideration along
              with pass and fail.
              <br />
              <br />
              <h5>Exam Marks-Sheet:</h5>
              WYC provides a predefined format of mark sheet where one can see
              the mark-sheet in the prescribed format.
              <br />
              <br />
              <h5>Exam Admit Card and Desk Slip:</h5>
              WYC provides facility to manager Admit card and desk slip
              generation and its download and print options.
              <br />
              <br />
              <h5>Supplementary Exam:</h5>
              WYC provides a feature to conduct supplementary exam and and
              facility for a revised marking.
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Exam;
