import React, { Component } from "react";
import Clientsliders from "react-slick";
import Sectiontitle from "../component/Banner/Sectiontitle";

class Clientslogo extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      arrows: false,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="clients_logo_area">
        <div className="container">
          <Sectiontitle Title="Our Team" TitleP="" />
          <Clientsliders {...settings} className="clients_slider">
            <div className="item">
              {/* <a href=".#">
                <img src={require("../image/c3.jpg")} alt="" />
              </a>{" "} */}
              <b> Amit Sinha<br />CEO <br/>(M.Sc.Tech- BHU,Varanasi  )</b>
            </div>

            <div className="item">
              {/* <a href=".#">
                <img src={require("../image/c2.jpg")} alt="" />
              </a>{" "} */}
              <b>Ajay Dubey<br />CMO <br/>(MBA,Marketing)  </b>
            </div>

            <div className="item">
              {/* <a href=".#">
                <img src={require("../image/c1.jpg")} alt="" />
              </a>{" "} */}
              <b>Alok Sinha<br />CTO <br/>(IIT-Varanasi & MBA)</b>
            </div>


            <div className="item">
              {/* <a href=".#">
                <img src={require("../image/c4.jpg")} alt="" />
              </a>{" "} */}
              <b>Arun Dubey<br />AMO <br/>(MBA,Marketing)</b>
            </div>
            <div className="item">
              {/* <a href=".#">
                <img src={require("../image/c4.jpg")} alt="" />
              </a>{" "} */}
              <b>Harpreet Singh<br />CBO<br/>(MBA,Marketing)</b>
            </div>  
          </Clientsliders>
        </div>
      </section>
    );
  }
}
export default Clientslogo;
