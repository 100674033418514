import React, { Component } from "react";
import SectionTitleTwo from "../Banner/SectionTitleTwo";
import Slider from "react-slick";

class TestimonialTwo extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      arrows: false,
      autoplay: true,
      pauseOnHover: true,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div>
        {/* <NavbarTwo mClass="menu_two" mainlogo="logo-blue.png" stickylogo="logo-blue.png"/> */}
        <div className="testimonial_area_two" id="testimonial">
          <div className="container">
            <div className="row">
              {/* <div className="col-lg-6">
                <div className="testimonial_img">
                  <img src={require('../../image/slider/Admin.png')}  height="500" width="500" alt=""/>
                </div>
              </div> */}
              <div className="col-lg-12">
                <SectionTitleTwo
                  stitle="How it works   "
                  btitle="What are we offering"
                />
                <div className="testimonial_slider_info">

                  <div>
                    <Slider {...settings} className="testimonial_slider_Two">
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              className="slider-img-item"
                              src={require("../../image/slider/Admin.png")}
                              alt=""
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="media-body">
                              <h6>Admin App</h6>
                            </div>
                            <p>
                              Now Your School is in your hands. Your daily tasks
                              can be handled on your smartphone.
                            </p>
                            <div className="media">
                              <div className="author_img">
                                <div className="row">
                                  <div className="col" style={{ marginRight: "-60px" }}>
                                    <img src={require('../../image/PlayStore/Group666.png')} alt="" />
                                  </div>
                                  <div className="col">
                                    <img src={require('../../image/PlayStore/Group677.png')} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              className="slider-img-item"
                              src={require("../../image/slider/driver.png")}
                              alt=""
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="media-body">
                              <h6>Driver App</h6>
                            </div>
                            <p>
                              Now the bus attendant can mark the students
                              boarding and deboarding the bus, in real time,
                              enabling a transparent child tracking for the
                              parents and the school authorities.
                            </p>
                            <div className="media">
                              <div className="author_img">
                                <img style={{ width: "70px", height: "70px" }} src={require('../../image/PlayStore/Group666.png')} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              className="slider-img-item"
                              src={require("../../image/slider/parent.png")}
                              alt=""
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="media-body">
                              <h6>Parent App</h6>
                            </div>
                            <p>
                              Now parents, can get real time info on their
                              ward's attendance, marks and report card download,
                              events, pictures, videos, homework, applying
                              leave, pay fee online, and get the live bus
                              tracking, all in one app on their finger tips.
                            </p>
                            <div className="media">
                              <div className="author_img">
                                <div className="row">
                                  <div className="col" style={{ marginRight: "-60px" }}>
                                    <img src={require('../../image/PlayStore/Group666.png')} alt="" />
                                  </div>
                                  <div className="col">
                                    <img src={require('../../image/PlayStore/Group677.png')} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              className="slider-img-item"
                              src={require("../../image/slider/Teachers.png")}
                              alt=""
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="media-body">
                              <h6>Teacher App</h6>
                            </div>
                            <p>
                              Teacher can mark attendance, approve / deny leave,
                              share homework, do marks entry, share feedback and
                              share files on broadcast groups.
                            </p>
                            <div className="media">
                              <div className="author_img">
                                <div className="row">
                                  <div className="col" style={{ marginRight: "-60px" }}>
                                    <img src={require('../../image/PlayStore/Group666.png')} alt="" />
                                  </div>
                                  <div className="col">
                                    <img src={require('../../image/PlayStore/Group677.png')} alt="" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              className="slider-img-item"
                              src={require("../../image/slider/console.png")}
                              alt=""
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="media-body">
                              <h6>Admin Console </h6>
                            </div>
                            <p>
                              Way Your Child provides a Usable Dashboard where one can look at all important activities details of school.
                            </p>
                            <div className="media">
                              {/* <div className="author_img">
                            <img src={require('../../image/slider2.png')} alt=""/>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              className="slider-img-item"
                              src={require("../../image/slider/jpstracking.png")}
                              alt=""
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="media-body">
                              <h6>Online Payment </h6>
                            </div>
                            <p>
                              Mobile APP having seamless Integration with PAYU-BIZ payment gateway, where any individual can do fees payment with nominal service charges.
                            </p>
                            <div className="media">
                              {/* <div className="author_img">
                            <img src={require('../../image/slider2.png')} alt=""/>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              className="slider-img-item"
                              src={require("../../image/slider/payment.png")}
                              alt=""
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="media-body">
                              <h6>GPS Tracking</h6>
                            </div>
                            <p>
                              Way Your Child provides visual representation in parent APP for realtime tracking of school buses along with bus attendance & Notifications.
                            </p>
                            <div className="media">
                              {/* <div className="author_img">
                            <img src={require('../../image/slider2.png')} alt=""/>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="row">
                          <div className="col-lg-6">
                            <img
                              className="slider-img-item"
                              src={require("../../image/slider/Biometric.png")}
                              alt=""
                            />
                          </div>
                          <div className="col-lg-6">
                            <div className="media-body">
                              <h6>Biometric Integration</h6>
                            </div>
                            <p>
                              Way Your Child provides a seamless integration of biometric where teachers' attendance with their IN-TIME/OUT-TIME can be registered via fingerprint Scanning.
                            </p>
                            <div className="media">
                              {/* <div className="author_img">
                            <img src={require('../../image/slider2.png')} alt=""/>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TestimonialTwo;
