import React, { Component } from "react";
import image from "../../image/allCont/all1.png";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

class SchoolManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <div className="container">
          <Row className="class">
            <Col>SCHOOL ERP MANAGEMENT</Col>
          </Row>
          <Row>
            <Col className="image">
              <img className="size" src={image}></img>
            </Col>
          </Row>
          <Row>
            <Col className="content">
              <p>
                A complete cloud based Digital Teachers and Students ERP system
                including all others staff. Excellent and seamless UI interface
                of Mobile APP access via individual mobile numbers logins.
             </p>
            </Col>
          </Row>
          <Card className="box">
            <CardHeader>
              <h4>School ERP and Management</h4>
               We provide a complete cloud based ERP system.It enables to manage the students ERP and also teachers/Employees
                ERP. We provide a seamless and very easy process to setup and manage the Student ERP and Teachers ERP.
              <h5> The following Features are:</h5>
            </CardHeader>

            <CardBody>
              <h5>BULK UPLOAD:</h5>
              In this we provide{" "}
              <strong className="strong">Bulk Upload Features</strong> which enable us to upload student and teacher’s information at one go
                   without any hassles <strong className="strong"> through XLS bulk upload feature. </strong>
              <br />
              <br />
              <h5>QR Code Integration:</h5>
              WYC provides a<strong className="strong"> QR code Integrated profile </strong>
              creation which would be used for RFID card generation for teachers/Students.
              <br />
              <br />
              <h5>Student Profile</h5>
              WYC also provides an excellent UI Interface  to view all the
              related details of students or teachers at only one webpage. It
              consists of (student-profile/Fees
              Details/Invoices/payment <br />/Subjects/Attendance/Transport) details and
              Fees/Time Table.
              <br />
              <br />
              <h5>Mobile Logins:</h5>
              WYC works on individual<strong className="strong"> Mobile based login system.</strong> An
               individual parent/Teachers/Admin can login with mobile based
               authentication.
              <br />
              <br />
              <h5>SMS:</h5>
              <strong className="strong"> SMS services integration </strong>are
               enabled with ERP systems.
              <br />
              <br />
              <h5>Android Application:</h5>
              WYC also provides<strong className="strong">Mobile APP based application </strong>
              (android based), which enables parents and/or student and teachers
               to access their profile, can update profile images, change/edit
                mobile and password. Profile related information can be changed
                only via admin.
              <br />
              <br />
              <h5>Usable Dashboard:</h5>
              WYC provides a<strong className="strong"> Usable Dashboard </strong>where one
             can look all important activities details of school.
             <br />
              <br />
              <h5>De-activate / Delete:</h5>
               mechanism for any individuals who are leaving the schools for both
               student and teachers.
               <br />
              <br />
              <h5>Global Search:</h5>
               WYC also provides a <strong className="strong">
                Global search option and Filter options based on wide variety of
              criteria</strong>for teachers or students from School ERP database.
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default SchoolManagement;
