import React from "react";
import { Reveal } from "react-reveal";
import FeePay from "./FeePay";

const SchoolFee = () => {
  return (
    <>
      <div>
        <section className="contact-area" id="contact">
          <div className="container">
            <div className="row row-reverse">
              <div className="col-md-12">
                <Reveal effect="fadeInRight" duration={800}>
                  <div className="input_form">
                    <h4>Student School Fee Payment</h4>
                    <div className="wizard-test">
                      <FeePay />
                    </div>
                    <div id="success">Your message succesfully sent!</div>
                    <div id="error">
                      Opps! There is something wrong. Please try again
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>
        <section></section>
        {/* <Footer jhonData={jhonData}/> */}
      </div>
    </>
  );
};

export default SchoolFee;
