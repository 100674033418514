import React from "react";
import API from "../API";
import SweetAlert from '../component/SweetAlert';
import { Row, Col, Label, Form } from 'reactstrap';
import { AvForm, AvInput, AvGroup, AvFeedback, AvField } from 'availity-reactstrap-validation';

class FormSchool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sweetAlert: {
        show: false,
        msg: "",
        type: "", // success, error, warning, info
        title: "",
        // redirectUrl: "",
      },
    };
  }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  };

  submitHandler = (e, errors, values) => {
    if (errors?.length) {
      return;
    }
    let body = {
      firstName: this.state.firstName,
      middleName: this.state.middleName,
      lastName: this.state.lastName,
      fullName: this.state.firstName + this.state.lastName,
      email: this.state.email,
      mobileNumber: this.state.mobile,
      alternateNumber: this.state.alternateNumber,
      designation: this.state.designation,
      schoolName: this.state.schoolName,
      address: this.state.address,
      city: this.state.city,
      schoolStrength: this.state.strength,
      description: this.state.description
    };
    API.postSchoolRegistration(body)
      .then((resp) => resp.json())
      .then((jsonResp) => {
        if (jsonResp.success) {
          const alert = {
            show: true,
            msg: 'School Request successfully.',
            type: 'success',
            title: 'Success',
            redirectUrl: '/',
          }
          this.setState({ sweetAlert: alert });
        } else {
          const alert = {
            show: true,
            msg: ' School Request Failed .',
            type: 'error',
            title: 'Error',
            // redirectUrl: '/',
          }
          this.setState({ sweetAlert: alert });
        }
      })
  };


  handleConfirm = () => {
    const alert = {
      show: false,
    };
    this.setState({ sweetAlert: alert });
  };

  render() {
    const { errors, sweetAlert } = this.state;
    return (
      <React.Fragment>
        <AvForm onSubmit={this.submitHandler} >
          <div className="row">
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">First Name </label>{''}
                <span className="alidations">*</span>
                <AvInput
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  onChange={this.handleChange}
                  required
                />
                {/* <AvFeedback>first Name is invalid</AvFeedback> */}
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">Middle Name </label>
                <AvInput
                  type="text"
                  id="middleName"
                  name="middleName"
                  placeholder="Middle Name"
                  onChange={this.handleChange}
                />
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">Last Name </label>
                <AvInput
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  onChange={this.handleChange}
                />
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">School Name </label>
                <span className="alidations">*</span>
                <AvInput
                  type="text"
                  id="schoolName"
                  name="schoolName"
                  placeholder="School Name"
                  onChange={this.handleChange}
                  required={true}
                />
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">Email</label>
                <span className="alidations">*</span>
                <AvInput
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Id"
                  onChange={this.handleChange}
                  required={true}
                />
              </AvGroup>
            </Col>

            <Col lg="3">
              <AvGroup>
                <label className="School-Form">Designation</label>
                <span className="alidations">*</span>
                <AvInput
                  type="text"
                  id="designation"
                  name="designation"
                  placeholder="Designation*"
                  onChange={this.handleChange}
                  required={true}
                />
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">Mobile Number</label>
                <span className="alidations">*</span>
                <AvInput
                  type="text"
                  id="mobile"
                  name="mobile"
                  placeholder="Mobile Number"
                  onChange={this.handleChange}
                  required={true}
                />
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">Alternate Number </label>
                <AvInput
                  type="text"
                  id="alternateNumber"
                  name="alternateNumber"
                  placeholder="Alternate Number"
                  onChange={this.handleChange}
                />
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">Student Strength in School</label>
                <AvInput
                  type="number"
                  id="strength"
                  name="strength"
                  placeholder="Student Strength in School"
                  onChange={this.handleChange}
                />
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">City </label>
                <span className="alidations">*</span>
                <AvInput
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City"
                  onChange={this.handleChange}
                  required
                />
              </AvGroup>
            </Col>
            <Col lg="3">
              <AvGroup>
                <label className="School-Form">Address </label>
                <span className="alidations">*</span>
                <AvInput
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Address*"
                  onChange={this.handleChange}
                  required={true}
                />
              </AvGroup>
            </Col>
          </div>
          <AvInput
            type="textarea"
            name="description"
            id="description"
            rows="6"
            placeholder="Message"
            onChange={this.handleChange}
          />
          <button type="submit" className="btn send_btn theme_btn" >
            Request A Demo
        </button>
        </AvForm>
        <SweetAlert data={sweetAlert} handleConfirm={this.handleConfirm} />
      </React.Fragment>
    );
  }
}

export default FormSchool;
