import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from "react-router-dom";

const CustomSweetAlert = (props) => {
    let history = useHistory();

    const handleConfirm = () => {
        if(props.data.redirectUrl) {
            history.push(props.data.redirectUrl)
        }
        else if(props.handleConfirm)
        {
            props.handleConfirm();
        }
    }

    return (
        <div>
            {(props.data.type === 'success') ? 
                <SweetAlert success title={props.data.title}
                    show={props.data.show} 
                    onConfirm={handleConfirm}
                >  
                    <p className="sweet-alert-text">{props.data.msg}</p>
                </SweetAlert> : 
                (props.data.type === 'error') ? 
                    <SweetAlert error title={props.data.title}
                    show={props.data.show} 
                    onConfirm={handleConfirm}
                >  
                    <p className="sweet-alert-text">{props.data.msg}</p>
                </SweetAlert> : 
                (props.data.type === 'warning') ? 
                    <SweetAlert warning title={props.data.title}
                    show={props.data.show} 
                    onConfirm={handleConfirm}
                >  
                    <p className="sweet-alert-text">{props.data.msg}</p>
                </SweetAlert> :
                <SweetAlert info title={props.data.title}
                    show={props.data.show} 
                    onConfirm={handleConfirm}
                >  
                    <p className="sweet-alert-text">{props.data.msg}</p>
                </SweetAlert>
            }
        </div>
    );
};

export default CustomSweetAlert;